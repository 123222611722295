import React from 'react'
import { useParams } from 'react-router-dom';
import Data from '../../../apiData/blog.json';
import styled from 'styled-components';
import YouTube from "react-youtube";

const Description = styled.div`
  padding-inline: 5% ;
  h1{
    color: #033F1E;
    text-size: 1.9em;
    // padding-bottom: 13px;
    margin-top: 32px; 
    @media (max-width: 48em) {
      font-size: 1.5em;
    }
    @media(max-width: 30em){
      font-size: 1.2em;
    }
    }
  p{
    margin-top: 13px ;
    color: #1E1E1F;
    text-size: 2em;
    text-align: justify;
    @media (max-width: 48em) {
      font-size: .8em;
    }
    @media(max-width: 30em){
      padding-bottom: 4px;
    }

  }
  h2{
    color: #FBCA57;
    font-size: 1.7em;

    @media (max-width: 48em) {
      font-size: 1.3em;
    }
    @media(max-width: 30em){
      font-size: 1em;
    }
  }
  h5{
    color: green;
    font-size: 1.2em;
    padding-top: 10px;

    @media (max-width: 48em) {
      font-size: 1.1em;

    }
    @media(max-width: 30em){
      font-size: 1em;

    }
  }
`;
const ImageContainer = styled.div`
  max-width: 100%;
  // margin: 0 auto;
  // text-align: center;
  padding-bottom: 2%;
`;

const Image = styled.img`
width: 100%;
margin: auto;
@media (max-width: 64em) {
  width: 80%;
}

@media (max-width: 48em) {
  width: 100%;
}
`

const BlogDescription = () => {
  const widthSize = window.innerWidth;
  const ROOT_PATH = process.env.PUBLIC_URL + '/assets/ImagePackages';
  const { sN } = useParams();
  const selectedItem = Data.find((item) => item.sN === sN);
  const formatDescription = (description) => {
    const sections = description.split('@@');
    console.log("Sections",{sections});
    return sections.map((section, index) => {
      if (index % 2 === 0) {
        const textSections = section.split('--'); // Split on '--' markers
        console.log("textSections",{textSections});

        const formattedSections = textSections.map((text, i) => {
          if (i % 2 === 0) {
            return text;

          } 
          else {
            if (text.length > 0) {

            return <h5 key={i}>{text}</h5>;
            }else{
              return <br />
            }

          }
        });
        return (
          <p key={index}>
            {formattedSections.map((text, i) => (
              <span key={i}>{text}</span>
            ))}
          </p>
        );
      } else {
        return <h2 key={index}>{section}</h2>;
      }
    });
  };




  const renderMedia = () => {
    if (selectedItem.asnVideoPath) {
      return (
        // <video controls>
        //   <source src={selectedItem.asnVideoPath} type="video/mp4" />

        // </video>

        <YouTube videoId={selectedItem.asnVideoPath} opts={{
          playerVars: {
            controls: 1,
            autoplay: 1,

          },
          height: widthSize < 768 ? '220':'440' ,
          width: "95%",
        }} playing={true} loop={true} />
      );
    } else {
      return  <ImageContainer>
      <Image
        src={selectedItem.asnVideoPath
          ? `https://img.youtube.com/vi/${selectedItem.asnVideoPath}/0.jpg`
          : `${ROOT_PATH}/${selectedItem.img}`}
        alt={selectedItem.title}
      />
    </ImageContainer>
    }
  };

  return (

    <Description>
      {renderMedia()}
      <h1>{selectedItem.title}</h1>
      <p>{selectedItem.shortDescription}</p>
      <div> {formatDescription(selectedItem.description)}</div>
    </Description>
  )
}

export default BlogDescription
