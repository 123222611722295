import React,{useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import ReactGA from 'react-ga4';

import image001 from "../../Icons/Avtar/yoga1.png";
import image002 from "../../Icons/Avtar/yoga2.png";
import image003 from "../../Icons/Avtar/yoga3.png";
import image004 from "../../Icons/Avtar/yoga4.png";
import image005 from "../../Icons/Avtar/yoga5.png";
import imageS001 from "../../Icons/Avtar/YogaS1.png";
import imageS002 from "../../Icons/Avtar/YogaS2.png";
import imageS003 from "../../Icons/Avtar/YogaS3.png";
import imageS004 from "../../Icons/Avtar/YogaS4.png";
import imageS005 from "../../Icons/Avtar/YogaS5.png";

const Section = styled.section`
  width: 100vw;
  height: 25rem;
  position: relative;

  // border-top: 2px solid ${(props) => props.theme.text};
  // border-top: 2px solid ${(props) => props.theme.text};

  background-color: ${(props) => `rgba(${props.theme.textRgba},0.9)`};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media (max-width: 48em) {
    height: 30rem;
    flex-direction: column;
  }
`;

const ImgContainer = styled.div`
  width: 12rem;
  // top: 40%;
  // left: 50%;
  align-items: center;
  border-radius: 20px;
  justify-content: center;

  display: flex;

  opacity: 0.3;
  @media (max-width: 48em) {
    width: 12rem;
    height: auto;
  }
  @media (max-width: 30em) {
    width: 10rem;
    height: auto;
  }
  img {
    width: 100%;
    height: auto;
  }
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxl};
  color: ${(props) => props.theme.lightGreen};
  // padding: 1rem 2rem;
  z-index: 10;
  width: 35%;
  text-transform: capitalize;

  text-shadow: 1px 1px 2px ${(props) => props.theme.lightGreen};
  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxl};
    text-align: center;
    width: 40%;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontlg};
    padding: 2rem 0;
    width: 100%;
  }
`;

const BtnContainer = styled.div`
  width: 35%;
  display: flex;
  justify-content: flex-center;
  @media (max-width: 48em) {
    width: 100%;
    justify-content: center;
  }
`;

const JoiNow = styled.button`
  display: inline-block;
  background-color: ${(props) => props.theme.buttoncolorO};
  color: ${(props) => props.theme.text};
  outline: none;
  border: none;
  font-weight: 600;
  font-size: ${(props) => props.theme.fontlg};
  padding: 1.5rem 3rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  @media (max-width: 48em) {
    padding: 1rem 2rem;
  }
  @media (max-width: 30em) {
    padding: 0.5rem 2rem;
    font-size: ${(props) => props.theme.fontsm};
  }
  &:hover {
    transform: scale(0.9);
  }
  &::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    border: 2px solid ${(props) => props.theme.text};
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: all 0.2s ease;
  }
  &:hover::after {
    transform: translate(-50%, -50%) scale(1);
    padding: 0.3rem;
  }
`;
const move = keyframes`
0%{transform: translateX(100%)};
100%{transform: translateX(-100%)}  
`;

const Row = styled.div`
  white-space: nowrap;
  box-sizing: content-box;
  margin: 2rem;
  display: flex;
  animation: ${move} 20s linear infinite ${(props) => props.direction};
`;

const NftItem = ({ img }) => {
  return (
    <ImgContainer>
      <img src={img} alt="CA" />
    </ImgContainer>
  );
};

const Banner = () => {
  const navigate = useNavigate();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});

  }, []);

  return (
    <Section id="banner">
      <Row direction="alternate-reverse">
        <ImgContainer>
          <img src={image001} alt="CA" />
          <img src={imageS005} alt="CA" />
          <img src={image002} alt="CA" />
          <img src={imageS002} alt="CA" />
          <img src={image003} alt="CA" />
          <img src={imageS003} alt="CA" />
          <img src={image004} alt="CA" />
          <img src={imageS004} alt="CA" />
          <img src={image005} alt="CA" />
          <img src={imageS001} alt="CA" />
        </ImgContainer>
      </Row>
      <Title>
        Join now
        <br />
        Yoga Shastra
      </Title>
      <BtnContainer>
        <JoiNow
          onClick={() => {
            ReactGA.event({
              category: 'contactUs',
              action: 'contactUs Button Clicked'
            });
            navigate("/contactUs");
          }}
        >
          Book a Trial
        </JoiNow>
      </BtnContainer>
    </Section>
  );
};

export default Banner;
