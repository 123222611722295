import React from "react";
import styled from "styled-components";
import YouTube from "react-youtube";

import { Link } from "react-router-dom";
import ListComponent from "../../common/ListComponent";
const StyledLink = styled(Link)`
width: 25%;
margin: 2rem 1rem;

@media (max-width: 48em) {
  width: 35vw;
}

@media (max-width: 30em) {
  width: 70vw;
}

#item {
  width: 100%;
  margin: 0;

  @media (max-width: 48em) {
    width: 100%;
  }

  @media (max-width: 30em) {
    width: 100%;
  }
}
`;

const Item = styled.div`
width: 99%;
padding: 0.26 rem 0;
color: ${(props) => props.theme.TextColorBlack};
margin: 2rem 1rem;
position: relative;
z-index: 5;
backdrop-filter: blur(4px);
border-radius: 20px;
transition: all 0.3s ease;

&:hover {
  img {
    transform: translateY(-2rem) scale(1.2);
  }
}
@media (max-width: 48em) {
  width: 99%;
}

@media (max-width: 30em) {
  width: 99%;
}
`;
const ListItem = styled.div`
width: 99%;
padding: 1rem 0;
color: ${(props) => props.theme.TextColorBlack};
margin: 2rem 1rem;
position: relative;
z-index: 5;
backdrop-filter: blur(4px);

border: 2px solid ${(props) => props.theme.TextColorBlack};
border-radius: 20px;
transition: all 0.3s ease;

&:hover {
  img {
    transform: translateY(-2rem) scale(1.2);
  }
}
@media (max-width: 48em) {
  width: 99%;
}

@media (max-width: 30em) {
  width: 99%;
}
`;

const ImageContainer = styled.div`
width: 90%;
margin: 0 auto;
// background-color: ${(props) => props.theme.carouselColor};
border: 1px solid ${(props) => props.theme.TextColorBlack};
padding: 0.5rem;
border-radius: 20px;
cursor: pointer;
img {
  width: 100%;
  height: auto;
  object-fit: fill;
  border-radius: 20px;
  transition: all 0.3s ease;
}
`;


const Name = styled.h2`
  padding: 0px 0px;
  font-size: ${(props) => props.theme.fontlg};
  font-weight:700;
  justify-content: left;
  text-transform: uppercase;

  color: ${(props) => props.theme.TextColorBlack};
  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxl};
    text-align: center;
    width: 100%;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontlg};
    text-align: center;
    width: 100%;
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
    padding-top: 10px;
  }
`;
const Description = styled.h2`
  white-space: pre-wrap;
  margin-right: inherit;
  margin-top: 0.5rem;
  text-align: justify;
  margin-bottom: 2rem;
  // padding: 0px 10px;
  margin-right: 1.5rem;
  // background-color:red;

  font-size: ${(props) => props.theme.fontmd};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.TextColorBlack};
  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontmd};
    text-align: center;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontsm};
    text-align: justify;
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontxs};
    text-align: justify;
  }
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.TextColorBlack};
  padding: 0px 10px;
  
  text-transform: capitalize;
  margin-top: .5rem;
  font-weight:700;

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontlg};
    padding-top: 15px;
    margin-top: .45rem;

  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
    padding-top: 13px;
    margin-top: .25rem;

  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
    padding-top: 10px;
    margin-top: .15rem;

  }
`;

const Position = styled.h2`
  font-size: ${(props) => props.theme.fontmd};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${(props) => `rgba(${props.theme.TextColorBlack},0.9)`};
  margin-top: 1rem;
  font-weight: 400;
`;

const DetailComponent = (props) => {
  const widthSize = window.innerWidth;
  var imgPath = props.img;
  var ROOT_PATH = process.env.PUBLIC_URL+`/assets/yoga`;
  if (props.tileData.asnVideoPath != undefined){
    imgPath = "https://img.youtube.com/vi/".concat(props.tileData.asnVideoPath).concat("/maxresdefault.jpg")
  }else if (props.tileData.img != undefined){
    imgPath = `${ROOT_PATH}/${props.tileData.img}`
  }
  return props?.link ? (
    <StyledLink key={props.renderKey} to={`${props.link}`}>
      <Item id="item">
        {/* <ImageContainer>
          <img src={props.img} alt={props.tileData.name} />
        </ImageContainer> */}
        {/* <Name>{props.tileData.name}</Name> */}
        {/* <Position>{position}</Position> */}
      </Item>
    </StyledLink>
  ) : (
    <Item id="item">
      <Name>{props.tileData.name}</Name>
      {/* <Title>Description:</Title> */}
      <Description>{props.tileData.description}</Description>
        {props.tileData.asnVideoPath? <YouTube videoId={props.tileData.asnVideoPath} opts={{
        playerVars: {
          controls: 1,
          autoplay: 1,
        },
        height: widthSize < 768 ? '220':'440' ,
        width: "95%",
      }} playing={true} loop={true} /> :      
      <ImageContainer>
      <img src={imgPath} alt={props.tileData.name} />
      </ImageContainer>}
      
      <Title>Instruction:</Title>
      <ListComponent listString={props.tileData.instruction}/>
      <Title>Benifits:</Title>
      <ListComponent listString={props.tileData.benifits}/>
      <Title>Concequences:</Title>
      <ListComponent listString={props.tileData.concequences}/>
    </Item>
  );
};

export default DetailComponent;
