import React from 'react'
import styled from 'styled-components'
import Data from '../../../apiData/blog.json'
import { useState } from 'react';
import { Link } from 'react-router-dom';




const Section = styled.div`
width: 100%;
padding-inline: 5%;
padding-bottom: 5%;
background-color: #F8F6F4;



`
const Title = styled.h1`

color: #FBCA57;
font-size: 3em;
padding-block: 2%
`

const BtnSection = styled.div`
display: flex;
justify-content: flex-start;
gap: 40px;
flex-dirction: row;
align-item: center;
@media (max-width: 48em) {
  // width: 100%;
  // flex-direction: column:
  gap: 10px;
  flex-wrap: wrap;
}
@media (min-width: 48em) and (max-width: 768px) {
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
`
const CardMainSection = styled.div`

`
const CardSection = styled.div`
width: 100%;
height: auto;
display: flex;
flex-wrap: wrap;
justify-content: center;
gap: 30px;
&:hover {
  img {
    transform: translate(0%, 0%) scale(1);
    
    
  }
}






`
const Button = styled.button`
  margin: 10px;
  // width: 100%;
  color: #1A3E2A;
  background-color: ${(props) => (props.selected ? 'yellow' : 'transparent')};
  // width: fit-content;
  
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  // color: ${(props) => props.theme.buttonColor};
  &::after {
    content: " ";
    display: block;
    width: 0%;
    height: 3px;
    background: green;
    transition: width 0.3s ease;
  }
  &:hover::after {
    width: 100%;
  }
  @media (max-width: 48em) {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    // justify-content: center;
    align-item: center;
    flex-wrap: wrap;
    flex-direction: column;
  
    
  
  }
`;
const ImageContainer = styled.div`

width: 100%;
position: relative;
margin: 0 auto;
height: 408px; 

cursor: pointer;
@media (max-width: 48em) {
  height: 100%; 
  

}



img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  border-radius: 20px;
  transition: all 0.3s ease;
  @media (max-width: 48em) {
    height: 75%;

  }

 
 
}
h4{
  position: absolute;
  z-index: 30;
  // width: 50%;
  right: 3%;
  padding-top:10px;
  padding-left:7px;
  padding-right:7px;
  border-left: 5px solid  ${(props) => props.theme.buttonColor};
  padding-bottom:10px;
  top: 4%;
  left: 3%;
  font-size: .8em;
  @media (max-width: 48em) {
    font-size: .8em;
  }
  @media(max-width: 30em){
    font-size: .7em;
  }


  color: rgb(255 255 255);

    text-shadow: black 5px 0px 8px;
    background-color: #375037c9;
    border-radius: 10px;
  &:hover::after {
    width: 100%;
  }

}



`

const Card = styled.div`

display: flex;
border-radius: 20px;

// justify-content: center;
flex-direction: column;
background-color: white;
// gap: 40px;
p{
  font-size: .9em;
  text-align: justify;
  padding-inline: 3%;
  font-weight: 400;
  @media (max-width: 48em) {
    font-size: .8em;
  }
  @media(max-width: 30em){
    font-size: .7em;
  }
}
h3{
  font-size: 1.8em;
  text-decoration: none;
  color: #1A3E2A;
  font-weight: 600;
  padding: 3%;
  @media (max-width: 48em) {
    font-size: 1.5em;
  }
  @media(max-width: 30em){
    font-size: 1em;
  }
}

`;
const Linked = styled(Link)`
width: 48%;
text-decoration-style: none;
text-decoration: none;
&:hover {
  text-decoration: none; 
  color: #1A3E2A;
}
@media(max-width: 30em){
  width: 100%;
}



`

function Blog() {
console.log(Data);

  Data.sort(function(a, b){
    // console.log(a,b)
    return b.sN - a.sN;
});

console.log(Data);

  const [selectedCategory, setSelectedCategory] = useState(null);


  const uniqueCategories = [...new Set(Data.map((item) => item.category))];

 
  const filteredPosts = selectedCategory
    ? Data.filter((item) => item.category === selectedCategory)
    : Data;
    
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top smoothly
    };
    var ROOT_PATH = process.env.PUBLIC_URL+`/assets/ImagePackages`;

  return (

   <Section>
   {/* <Title>Blog</Title> */}
   <BtnSection>
   <Button onClick={() => setSelectedCategory(null)}>All</Button>
  
   {uniqueCategories.map((category) => (
     <Button
       key={category}
       onClick={() => setSelectedCategory(category)}
    
     >
       {category}
     </Button>
   ))}
 </BtnSection>
 <hr />
   <CardSection >
   {filteredPosts.map((item) => (
     <Linked  onClick={scrollToTop} target= {item.link ?"_blank":""} key={item.sN} to={item.link ? `${item.link}`: `/description/${item.sN}`}>
       <Card>
       <ImageContainer>
       
        <h4>{item.title}</h4>
        <img src={item.asnVideoPath ? `https://img.youtube.com/vi/${item.asnVideoPath}/hqdefault.jpg` : `${ROOT_PATH}/${item.img}`} alt={item.title} />
       </ImageContainer>
       <h3>{item.title}</h3>
       <p>{item.name}  /  Date: {item.date}  /  {item.category}</p>
       <p>{item.shortDescription}</p>
         
       </Card>
     </Linked>
   ))}
 </CardSection>
   
   
   </Section>
  

  )
}

export default Blog
