import React from "react";
import styled from "styled-components";

const Description = styled.li`
white-space: pre-wrap;
margin-top: 0.5rem;
margin-bottom: 0.5rem;
margin-left: 0rem;
margin-right: 1rem;

padding: 0px 20px 0px 5px;
font-size: ${(props) => props.theme.fontsm};
color: ${(props) => props.theme.buttonColor};
text-align: justify;
@media (max-width: 48em) {
  font-size: ${(props) => props.theme.fontsm};

}

@media (max-width: 30em) {
  font-size: ${(props) => props.theme.fontsm};

}
`;

const Title = styled.ol`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.buttoncolorO};
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxs};
  
  }
  
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontxs};
  
  }
`;


const ListComponent = (props) => {
  const listArr = props.listString.split("&&");
  console.log("ListArray",listArr);
  const listItems = listArr.map((item) =>
    <Description key={item.id}>
       {item}
     </Description>
  );
  console.log('---------', props);
  return (
    <Title>{listItems}</Title>
  );
};

export default ListComponent;
