import React,{useState} from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import eyeIcon from "../../../../src/assets/playList.png";

const StyledLink = styled(Link)`
  width: 25%;
  margin: 1rem 1rem;
  text-decoration: none !important;
  
  @media (max-width: 64em) {

  }
  @media (max-width: 48em) {
    width: 35%;
    margin: 0.2rem .5rem;
  }

  @media (max-width: 30em) {
    width: 88%;
    margin: 0.2rem 1rem;
  }

  #item {
    width: 100%;
    margin: 0;
    @media (max-width: 48em) {
      width: 100%;
    }

    @media (max-width: 30em) {
      width: 100%;
    }
  }
`;

const Item = styled.div`
  width: 25%;
  padding: 1rem 0;
  color: ${(props) => props.theme.body};
  position: relative;
  z-index: 5;
  transition: all 0.3s ease;
  &:hover {
    #avatar {
      transform: translateY(-2rem) scale(1.2);
    }
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background-color: ${(props) => props.theme.carouselColor};
  border: 1px solid ${(props) => props.theme.TextColorBlack};
  padding: 0.2rem;
  border-radius: 5px;
  cursor: pointer;
  img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
`;

const NameFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-item:center;
`;

const Name = styled.h2`
  // padding: 0px 10px;
  font-size: ${(props) => props.theme.fontxs};
  display: flex;
  align-items: left;
  justify-content: left;
  text-transform: uppercase;
  color: ${(props) => props.theme.TextColorBlack};
  margin-top: 1rem;
  display: inline;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  font-weight: bold;

`;
const Description = styled.h2`
  font-size: ${(props) => props.theme.fontxs};
  align-items: left;
  justify-content: left;
  color: ${(props) => props.theme.TextColorBlack};
  margin-top: 1rem;
  display: inline;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  @media (max-width: 64em) {
    margin-top: .8rem;

  }
  @media (max-width: 48em) {
    margin-top: .6rem;

  }
  @media (max-width: 30em) {
    margin-top: .4rem;

  }
`;
const Position = styled.h2`
  font-size: ${(props) => props.theme.fontmd};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${(props) => `rgba(${props.theme.textRgba},0.9)`};
  margin-top: 1rem;
  font-weight: 400;
`;
const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top smoothly
};
const RogyogComponent = (props) => {
  return (
    <StyledLink onClick={scrollToTop} key={props.renderKey} to={`${props.link}`}>
      <Item id="item">
        <ImageContainer>
        {props.tileData.catImage? <img id="avatar" src={`https://img.youtube.com/vi/${props.tileData.catImage}/0.jpg`} alt={props.tileData.name} />:<img id="avatar" src={props.img} alt={props.tileData.name} />}
        </ImageContainer>
        <NameFlex>
          <Name>{props.tileData.name}</Name>
          <NameFlex>
            <img
              src={eyeIcon}
              alt={props.tileData.name}
              width="20px"
              height="20px"
              align-item="center"
              style={{
                marginRight: "10px",
                marginTop: ".8rem",
                color:"white",
              }}
            />
            {props?.tileData?.subcat && (
              <Name>{props.tileData.subcat.length}</Name>
            )}
          </NameFlex>
        </NameFlex>
        <Description>{props.tileData.description}</Description>
      </Item>
    </StyledLink>
  )
};

export default RogyogComponent;
