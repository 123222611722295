import React from "react";
import { useLayoutEffect } from "react";
import { useRef } from "react";
import styled from "styled-components";
import DrawSvg from "../../common/DrawSvg";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ConfettiComponent from "../../common/ConfettiComponent";

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.body};
  position: relative;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxl};
  text-transform: uppercase;
  color: ${(props) => props.theme.buttonColor};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  // border-bottom: 2px solid ${(props) => props.theme.buttonColor};
  width: fit-content;
  padding-top: 80px;
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontlg};
    padding-top: 50px;
  }
`;

const Container = styled.div`
  width: 70%;
  height: 350vh;
  background-color: ${(props) => props.theme.body};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 64em) {
    width: 80%;
  }

  @media (max-width: 48em) {
    width: 90%;
  }
`;
const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightblue;
`;
const Items = styled.ul`
  list-style-type: style none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 48em) {
    width: 90%;
  }

  & > *:nth-of-type(2n + 1) {
    justify-content: start;
    @media (max-width: 48em) {
      justify-content: center;
    }
    div {
      border-radius: 50px 0 50px 0;
      text-align: left;
      @media (max-width: 48em) {
        border-radius: 0 50px 0 50px;
        text-align: right;
        p {
          border-radius: 0 40px 0 40px;
        }
      }
    }
    p {
      border-radius: 40px 0 40px 0;
    }
  }
  & > *:nth-of-type(2n) {
    justify-content: end;
    @media (max-width: 48em) {
      justify-content: center;
    }
    div {
      border-radius: 0 50px 0 50px;
      text-align: left;
    }
    p {
      border-radius: 0 40px 0 40px;
    }
  }
`;
const Item = styled.li`
  width: 100%;
  height: 100%;
  display: flex;
  @media (max-width: 48em) {
    justify-content: flex-end !important;
  }
`;

const ItemContiner = styled.div`
  width: 40%;
  height: fit-content;
  padding: 1rem;
  border: 3px solid ${(props) => props.theme.buttonColor};
  @media (max-width: 48em) {
    width: 90%;
  }
`;

const Box = styled.p`
  height: fit-content;
  background-color: ${(props) => props.theme.lightGreen};
  color: ${(props) => props.theme.text};
  padding: 1rem;
  position: relative;
  border: 1px solid ${(props) => props.theme.buttoncolorO};
`;
const SubTitle = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontsm};
  text-transform: capitalize;
  color: ${(props) => props.theme.buttonColor};
  font-weight: bold;
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
    font-weight: bold;
  }
`;
const Text = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontxs};
  text-transform: capitalize;
  color: ${(props) => props.theme.textg};
  font-weight: 400;
  margin: 0.5 rem 0;
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
  }
`;
const RoadMapItem = ({ title, subText, addToRef }) => {
  return (
    <Item ref={addToRef}>
      <ItemContiner>
        <Box>
          <SubTitle>{title}</SubTitle>
          <Text>{subText}</Text>
        </Box>
      </ItemContiner>
    </Item>
  );
};

const Roadmap = () => {
  const revealRefs = useRef([]);
  revealRefs.current = [];
  gsap.registerPlugin(ScrollTrigger);
  const addToRefs = (eL) => {
    if (eL && !revealRefs.current.includes(eL)) {
      revealRefs.current.push(eL);
    }
  };

  useLayoutEffect(() => {
    let t1 = gsap.timeline();
    revealRefs.current.forEach((eL, index) => {
      t1.fromTo(
        eL.childNodes[0],
        {
          y: "0",
        },
        {
          y: "-30%",

          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: eL,
            start: "top center+=200px",
            end: "bottom center",
            scrub: true,
            // markers:true,`
          },
        }
      );
    });
    return () => {};
  }, []);

  return (
    <Section id="roadmap">
      {/* <ConfettiComponent /> */}

      <Title>Yogpath</Title>
      <Container>
        <SvgContainer>
          <DrawSvg />
        </SvgContainer>
        <Items>
          <Item>&nbsp;</Item>
          <RoadMapItem
            addToRef={addToRefs}
            title="Physical health"
            subText="Yoga has been found to improve overall physical health by increasing flexibility, strength, and balance, reducing the risk of injury, and improving cardiovascular health."
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Mental health"
            subText="Yoga has been shown to reduce stress, anxiety, and depression, improve mood, and increase feelings of well-being."
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Pain management"
            subText="Yoga has been found to be an effective tool for managing chronic pain, including back pain, neck pain, and joint pain."
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Sleep"
            subText="Practicing yoga has been found to improve sleep quality and quantity, leading to better overall health."
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Cognitive function"
            subText="Studies have shown that yoga can improve cognitive function, including attention, memory, and processing speed."
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Addiction recovery"
            subText="Yoga has been used as an adjunct therapy to aid in addiction recovery, by reducing stress and improving emotional regulation."
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Prenatal"
            subText="Prenatal yoga can have numerous benefits for women in Paraguarí who are pregnant.It improves physical & mental health, Reduced stress and anxiety, Preparation for labor and delivery and Bonding with baby."
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Postnatal "
            subText="Postnatal yoga can have many benefits for women in Paraguarí who have recently given birth.It improves physical & mental health, Reduced stress, Recovery from childbirth and Social support."
          />
        </Items>
      </Container>
    </Section>
  );
};

export default Roadmap;
