import React from "react";
import styled from "styled-components";
import Instagram from "../../../Icons/Instagram";
import LinkedIn from "../../../Icons/LinkedIn";
import Facebook from "../../../Icons/Facebook";
import Youtube from "../../../Icons/Youtube";
import { useNavigate } from "react-router-dom";

const Section = styled.div`
  // width: 100vw;
  // height: auto;
  background-color: ${(props) => props.theme.yellowShade};
  // position: relative;
  // background-color: red;
  background-image: linear-gradient(   ${(props) => props.theme.yellowShade},${(props) => props.theme.lightBlueShade});

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  @media (max-width: 48em) {
    flex-direction: column-reverse;
    // width: 33%
    height: 100%;
  }

`;

const Bottom = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
// margin: auto 0;
align-items: center;
text-align: center;
color: ${(props) => props.theme.buttonColor};
@media (max-width: 48em) {
  flex-direction: column-reverse;
  padding-bottom: 10px;
  
}


`
const Container232 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  // background-color: green;
  @media (max-width: 48em) {
    flex-direction: column;
    width: 33%
    oreder: 1
   
  }
`;
const Container23 = styled.div`
  display: flex;
  // width: 100%;
  margin-left: -5%;
  

  flex-direction: row;
  align-items: center;
  justify-content: center;

  flex-shrink: 0;
  // background-color: red;
  @media (max-width: 48em) {
    // font-size: 2em;
    padding-top: 15px;
    // margin-left: -10px;
    // margin-left: -5%;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 26.6875em){
    margin-left: 5%;
  }
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-color: pink;

  @media (max-width: 48em) {
    width: 100%;
  }
`;


const IconLists = styled.div`
  display: flex;
  color: #D4EAD0;
  justify-content: center;
  // align-items: end;
  margin: 1rem auto;
  cursor: pointer;
  gap: 10px;
  & > * {
    padding-right: 0rem;
    transition: all 0.2s ease;
    &:hover {
      transform: scale(1.2);
    }
  }
`;

const MenuItems = styled.ul`
  list-style: none;
  // margin-top: auto 0;
  margin-bottom: 1rem;
  
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
 
`;

const Item = styled.li`
  width: fit-content;
  cursor: pointer;
  color: ${(props) => props.theme.buttonColor};

  &::after {
    content: " ";
    display: block;
    width: 0%;
    height: 2px;
    background: white;
    transition: width 0.3s ease;
  }
  &:hover::after {
    width: 100%;
  }
 
`;


const Wave = styled.div`
  display: block;
  position: relative;
  height:70px;
  width: 100%;
  background-color: ${(props) => props.theme.waveLightBlue};;
  // background-color: yellow;

  transform: scale(1, 1.5);
  
  &::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    height: 300px;
     background-color: ${(props) => props.theme.waveLightBlue};;
    //  background-color: red;

    width: 88%;

    left: -25%;
    top: -12px;
    clip-path: ellipse(70% 30% at 80% -7%); 

}

&::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 110%;
  height: 300px;
  background-color: white;

  right: -25%;
  top: -260px;
   clip-path: ellipse(100% 20% at 35% 100%); 
}

`

const Footer = () => {
  const navigate = useNavigate();

  const scrollTo = (id) => {
    let element = document.getElementById(id);
    element.scrollIntoView({
      behaviour: "smooth",
      block: "start",
      inline: "nearest",
    });
    
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top smoothly
  };
  return (
    <Section>
      <Bottom>
        <div>
          &copy; {new Date().getFullYear()} Yoga Shastra. All rights reserved.
        </div>
        <div>
          Powered By {" "}
          <a href="https://emogosoftwares.com/home" target="_blank" rel="noopener noreferrer">
            Emogo Softwares
          </a>
        </div>
      </Bottom>
      <Container232>
        <Left>
          <IconLists>
            <a
              href="https://www.instagram.com/yogashastraa/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram fill="#000000" stroke="#00401d" />
            </a>
            <a
              href="https://www.linkedin.com/in/yoga-shastra-366109273/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedIn fill="#000000" stroke="#00401d" />
            </a>
            <a
              href="https://youtube.com/@Yoga-Shastra"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Youtube fill="#000000" stroke="#00401d" />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100095046713869&mibextid=9R9pXO"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook fill="#000000" stroke="#00401d" />
            </a>
          </IconLists>
        </Left>
      </Container232>
      <Container23>
        <MenuItems style={{color: "#D4EAD0"}}>
          <Item onClick={() =>{ navigate("/privacypolicy"); scrollToTop()}}>Privacy Policy</Item>
          <Item onClick={() => {navigate("/cookiepolicy"); scrollToTop()}}>Cookie Policy</Item>
          <Item onClick={() => {navigate("/terms&condition"); scrollToTop()}}>Terms & Conditions</Item>
        </MenuItems>
      </Container23>
    </Section>
  );
};

export default Footer;
