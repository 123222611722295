import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import styled from "styled-components";
// import caLogo from '../assets/ComLogo.jpg'
import caLogo from "../../assets/YogashastraClear.png";

const LogoText = styled.div`
  width: ${(props) => (props.width ? props.width : `15%`)};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxxl};
    width: ${(props) => (props.width ? props.width : `35%`)};
  }
`;

const Logo = (props) => {
  return (
    <LogoText {...props}>
      {/* <Link to="/">
            CA.
            </Link> */}
      <img
        style={{ height: "100%", width: "100%" }}
        src={caLogo}
        alt="The Weirdos"
      />
      {/* <div>CA.</div> */}
    </LogoText>
  );
};

export default Logo;
