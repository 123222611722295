import React from "react";
import styled from "styled-components";
import ClipLoader from "react-spinners/ClipLoader"; // Assuming you're using react-spinners

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const Spinner = () => (
  <Overlay>
    <div style={{ width: '100px', margin: 'auto', display: 'block' }}>
      <ClipLoader color="#52bfd9" size={100}/>
    </div>
  </Overlay>
);

export default Spinner;
