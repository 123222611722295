export const light = {
  lightGreen: "#D4EAD0",
  darkGreen: "#516950",
  lightOrange: "FF8458",
  offWhite: "FFFAF4",
  gray: "#FAF9F6",

  //

  body: "#fff",
  textg: "#000000",
  text: "#000000",
  buttoncolorO: "#fde7b4",
  buttonColor: "#000000",
  bodyRgba: "255, 255, 255",
  textRgba: "32,32,32",
  carouselColor: "#EEEDDE",

  TextColorBlack:  "#000000",



  yellowShade: "#FFF2E6",
  lightBlueShade: "#EAFDFF",
  waveLightBlue: "#ECFCFC",

  //1em = 16px
  fontxs: "0.75em",
  fontsm: "0.875em",
  fontmd: "1em",
  fontlg: "1.25em",
  fontxl: "2em",
  fontxxl: "3em",
  fontxxxl: "4em",

  fontButton: "0.875em",
  navHeight: "4rem",

};

export const dark = {
  body: "#202020",
  textg: "#1a7038",
  text: "#202020",
  buttonColor: "#00401d",
  buttoncolorO: "#fbca57",
  bodyRgba: "32,32,32",
  textRgba: "255, 255, 255",
  leafColor: "#fdfde3",
  carouselColor: "#EEEDDE",

  //1em = 16px
  fontxs: "0.75em",
  fontsm: "0.875em",
  fontmd: "1em",
  fontlg: "1.25em",
  fontxl: "2em",
  fontxxl: "3em",
  fontxxxl: "4em",

  fontButton: "0.875em",
  navHeight: "7rem",
};

export const description = {
  benifitsOfYog: "Discover the transformative power of yoga and enhance your health and wellness journey. Yoga offers numerous benefits including improved flexibility, increased strength, stress relief, and better mental clarity. By integrating yoga into your daily routine, you can experience enhanced mindfulness and a balanced lifestyle. Join our online yoga classes and access tailored sessions designed to fit your busy schedule. Start your path to a healthier, happier you with our expert yoga instructors. Sign up today and unlock the benefits of yoga, from the comfort of your home."
}