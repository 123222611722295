import React from "react";
import styled from "styled-components";
import image1 from "../../../Icons/Avtar/yoga1.png";
import Data from "../../../apiData/yoga2.json";
import RogyogComponent from "../RogYog/RogyogComponent";
import { useState } from "react";
// import background from "../../../assets/BG7.jpg";

const Section = styled.section`
margin-top: -17px;
position: relative;
background-image: linear-gradient(   ${(props) => props.theme.lightBlueShade},${(props) => props.theme.yellowShade});
`;

const Title = styled.h1`
  // background-color: red;

  font-size: ${(props) => props.theme.fontxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.buttonColor};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  width: fit-content;
  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontlg};
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
  }
  }
`;
const Description = styled.h1`
font-size: 0.9rem;
padding: 10px 12% 10px 12%;
line-height: 1.5;
overflow-wrap: break-word;
text-align: center;
color: ${(props) => props.theme.TextColorBlack};
@media (max-width: 64em) {
  font-size: ${(props) => props.theme.fontmd};
  text-align: center;
}
@media (max-width: 48em) {
  font-size: ${(props) => props.theme.fontsm};
  text-align: justify;
}
@media (max-width: 30em) {
  font-size: ${(props) => props.theme.fontxs};
  text-align: justify;
}
`;

const Container = styled.div`
  width: 100%;
  margin: .3rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 64em) {
    align-items: center;
  }
  @media (max-width: 48em) {
    justify-content: center;
  }
`;
const Wave = styled.div`
  display: block;
  position: relative;
  height:70px;
  width: 100%;
  background-color: ${(props) => props.theme.waveLightBlue};;
  // background-color: yellow;

  transform: scale(1, 1.5);
  
  &::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    height: 300px;
     background-color: ${(props) => props.theme.waveLightBlue};;
    //  background-color: red;

    width: 88%;

    left: -30%;
    top: -14px;
    clip-path: ellipse(70% 30% at 80% -7%); 

}

&::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 110%;
  height: 300px;
  background-color: white;

  right: -25%;
  top: -260px;
   clip-path: ellipse(100% 20% at 35% 100%); 
}

`

const Wave2 = styled.div`
  display: block;
  position: relative;
  height: 40px;
  width: 100%;
  background-color:  ${(props) => props.theme.yellowShade};
  transform: scale(1, 1.5);
  
  &::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 300px;
  background-color: white;
  right: -25%;
  top: 20px;
}

&::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 300px;
  background-color:  ${(props) => props.theme.yellowShade};
  left: -25%;
  top: -240px;
  clip-path: ellipse(100% 15% at -15% 100%);
}

`
const Rogyog = ({isUpeerWave,isLowerWave}) => {
  console.log('asdasd',isLowerWave);

// console.log("selectedCategory ===>",selectedCategory);
  const renderTile = (singleData, index) => {
    const tileData = {
      sN:singleData.sN,
      name: singleData.category,
      description: singleData.categoryDescription,
      subcat: singleData.subcat,
      catImage:singleData.catImage,
      ...singleData,
    };
    return (
      <RogyogComponent
        img={image1}
        tileData={tileData}
        link={`/rogyog/${singleData.sN}`}
        // onClick={()=>{
        //   setSelectedCategory(singleData.sN);
        // }}
      />
    );
  };
  return (
    <Section id="rogYog">
     {isUpeerWave ?  <Wave/>:<></>}
      <Title>Rogyog</Title>
      <Description>
      YogaShastra tailor yoga practices to specific medical conditions. Explore our curated collection of asanas categorized by medical concerns, designed to provide holistic support on your journey to well-being. Whether you seek relief from chronic ailments or simply wish to enhance your health, discover the power of yoga personalized to your needs.
      </Description>
      <Container>{Data?.map(renderTile)}</Container>
      {isLowerWave ?  <Wave2/>:<></>}
    </Section>
  );
};
export default Rogyog;
