import React from "react";
import styled, { keyframes } from "styled-components";

import packages from "../../../apiData/yogaPackage.json";
import packageImage from "../../../assets/buddhism-statue-floral-garden-scene-generative-ai 1.png";
import "../Packages/Packages.css"
import { useNavigate } from "react-router-dom";
// import Button from "../../common/Button";
const Button = styled.button`
display: inline-block;
background-color: ${(props) => props.theme.buttonColor};
color: ${(props) => props.theme.body};
outline: none;
margin-bottom: 35%;
font-size: ${(props) => props.theme.fontsm};
padding: 0.9rem 2.3rem;
border-radius: 50px;
cursor: pointer;
transition: all 0.2s ease;
position: relative;
&:hover {
  transform: scale(0.9);
}
&::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  border: 2px solid ${(props) => props.theme.body};
  width: 100%;
  height: 100%;
  border-radius: 50px;
  transition: all 0.2s ease;
}
&:hover::after {
  transform: translate(-50%, -50%) scale(1);
  padding: 0.3rem;
}
`




const Packages = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/contactUs");
  };
  const handleClickPrenatal = () => {
    navigate("/prenatalQuery");
  };
  return (
    <div className="MainSection">
      <div className="ImageSection">
        <img className="packageImage" src={packageImage} alt="img" />
        <div className="packageContent">
          <h3>Packages</h3>
          <p>Choose the best suitable yoga package to suit your
          Personal goal. Each package is carefully designed for a
          Specific purpose. Each package contains common
          Yoga as well as Exclusive yogic practices that are
          Selected & masterfully Curated to help you
          Achieve your yoga objectives</p>
        </div>
      </div>


      <div >
        {packages.map((packageItem) => (
          
          
          <div key={packageItem.id} className="package-item" >
       
          
            <h2  className="package-name">{packageItem.name.replace(/^(.*)\s/, '$1\n')}</h2>
            <div className="package-list">
              <div  className="imageDataContner">
                <img src={packageItem.image} alt={packageItem.name} className="package-image" />
              </div>
              <div className="class-list">
                {packageItem.classArray.map((classItem) => (
                  <div key={classItem.id} className="class-item">
                    <div className="BoxContentPackages">
                      <div className="priceCircle"> <h5>{classItem.classPrice}</h5>
                      </div>
                      <div className="packageType">{classItem.className}</div>
                      <ul>
                        {classItem.classProperty.split('--').map((property, index) => (
                          <li key={index}>{property}</li>
                        ))}
                      </ul>
                      <div  className="btnSection">
                      <Button  onClick={() => {packageItem.id < 4 ?  handleClickPrenatal(): handleClick()} }>Join now</Button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>




    </div>



  );
};


export default Packages;
