
/**
* getShortDesc - Return short description from long description.
* @param {*} desc
* @returns {string} shortDescription
*/
const getShortDesc = (desc) => {
    let shortDesc = desc !== '' ? desc : '';
    if (shortDesc && shortDesc.length > 160) {
        shortDesc = shortDesc.substr(0, 160);
        shortDesc = shortDesc.substr(0, Math.min(shortDesc.length, shortDesc.lastIndexOf(" ")));
        shortDesc = shortDesc + '...';
    }
    return shortDesc;
};

const utilities = {
    getShortDesc
}

export default utilities;