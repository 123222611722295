import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const StyledLink = styled(Link)`
  margin: 2rem 1rem;
 
  #item {
    height: 408px
    margin: 0;
    &:hover{
      
    }

    @media (max-width: 48em) {
      
    }

    @media (max-width: 35em) {
      
    }
  }
`;

const Item = styled.div`
margin: 2rem 1rem;

  width: 25%;
  height: 408px
  color: ${(props) => props.theme.body};
  border: 2px solid ${(props) => props.theme.darkGreen};
  border-radius: 20px;

  &:hover {
    background-image: linear-gradient(   ${(props) => props.theme.yellowShade},${(props) => props.theme.lightBlueShade});
    transition: 0.5s;

    img {
      filter: brightness(1); 
      transform: translate(0%, 0%) scale(1.1);
      transition: 0.5s;

    }

    .know-more-button {
      opacity: 0;
    }

    .name {
      transition: 0.5s;
      border: 2px solid ${(props) => props.theme.darkGreen};

      background-color: ${(props) => props.theme.lightBlueShade};
      @media (max-width: 30em) {
        // bottom: -17px;
      }
    }
  }
  @media (max-width: 48em) {
    width: 35vw;

  }

  @media (max-width: 30em) {
    width: 70vw;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  position: relative;
  margin: 0 auto;
  border-radius: 20px;
  cursor: pointer;

  img {
    width: 100%;
    // filter: brightness(0.5);

    height: 100%;
    object-fit: object-fit;
    border-radius: 20px;
    transition: all 0.3s ease;
    @media (max-width: 48em) {
      filter: brightness(1);

    }
    @media (max-width: 40em) {
      filter: brightness(1);
    }
    @media (max-width: 30em) {
      filter: brightness(1);
    }
  }
`;

const Name = styled.h2`
  // padding: 0px 10px;
  text-align: center;
  width: 80%;
  padding: 0.6rem;
  height: auto;
  border-radius: 20px;

  border: 2px solid ${(props) => props.theme.darkGreen};
  background-color: ${(props) => props.theme.buttoncolorO};
  bottom: -24px;
  left: 10%;
  position: absolute;
  font-size: ${(props) => props.theme.fontlg};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #1e1e1f;
  font-weight: 600;
  margin-top: 1rem;
  transition: top 0.3s ease;
  text-transform: capitalize;
  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontlg};

  }

  @media (max-width: 40em) {
    bottom: -22px;
    font-size: ${(props) => props.theme.fontmd};

  }
  @media (max-width: 30em) {
    bottom: -20px;
    font-size: ${(props) => props.theme.fontsm};

  }
`;
const Position = styled.h2`
  font-size: ${(props) => props.theme.fontmd};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${(props) => `rgba(${props.theme.textRgba},0.9)`};
  margin-top: 1rem;
  font-weight: 400;
`;
const KnowMoreBtn = styled.div`
  // display: none;
  width: 70%;
  height: AUTO;
  text-decoration: none !important;

  padding: 0.6rem;
  position: absolute;
  left: 15%;
  top: 80%;
  text-align: center !important;
  border: 1px solid #fbca57;
  color: #fbca57 !important;
  background-color: #1a3e2a;
  font-size: 1em;
  opacity: 0;
  transition: opacity 0.3s ease;
  @media (max-width: 48em) {
    // width: 35vw;
    // font-size: 0.4rem;
  }

  @media (max-width: 40em) {
    // top: 10%;
    font-size: 0.8rem;
  }
`;

const MemberComponent = (props) => {
  const ROOT_PATH = process.env.PUBLIC_URL + `/assets/yoga`;
  const { asnVideoPath } = props.tileData; // Destructure asnVideoPath from props

  const getImageSource = () => {
    if (asnVideoPath) {
      // If asnVideoPath is available, use the YouTube video thumbnail
      return `https://img.youtube.com/vi/${asnVideoPath}/0.jpg`;
    } else {
      return `${ROOT_PATH}/${props.tileData.img}`;
    }
  };
  var imgPath = props.img;
  // var ROOT_PATH = process.env.PUBLIC_URL+`/assets/yoga`;
  // props.tileData.img ? imgPath = `${ROOT_PATH}/${props.tileData.img}` : imgPath = props.img;
  // imgPath = `${ROOT_PATH}/${props.tileData.img}
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top smoothly
  };
  const navigate = useNavigate();

  const sayHello = () => {
    // console.log(props.tileData);
    // console.log(props.singleData);
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top smoothly

    console.log(props.link);
    if (props.finalCat) {
      navigate(props.link);
    } else {
      if (props.dataArray.length > 1) {
        navigate(props.link);
      } else {
       var linkPart= props.link.split('/');
       console.log(linkPart); 
       var last = linkPart[linkPart.length - 1];
      last = last+'.1';
      linkPart.push(last);
      var newLink = linkPart.join('/');
      console.log(newLink); 
      navigate(newLink);
      }
    }

    // if (props.componentHierarchy == "cat") {

    //   if (props.tileData.subcat.length == 1) {
    //     if (props.tileData.subSubCat.length == 1) {
    //       // navigate(`/${props.singleData.sN}/${props.singleData.sN}`);
    //     } else {
    //     navigate(`/${props.singleData.sN}/${props.link}`);
    //   }
    //   } else {
    //     navigate(props.link);
    //   }
    // }
    // else if (props.componentHierarchy == "2") {
    //   console.log(props.tileData);
    //   if (props.tileData.subSubCat.length == 1) {
    //     // navigate(`/${props.singleData.sN}/${props.singleData.sN}`);
    //   } else {
    //     navigate(props.link);
    //   }
    // }
  };
  return (
    // <StyledLink
    //   onClick={scrollToTop}
    //   key={props.renderKey}
    //   to={`${props.link}`}
    // >
    <Item id="item" onClick={sayHello}>
      <ImageContainer>
        <img src={imgPath} alt={props.tileData.name} />
        <KnowMoreBtn className="know-more-button">
          Click to know more
        </KnowMoreBtn>

        <Name className="name">{props.tileData.name}</Name>
      </ImageContainer>
      {/* <Position>{position}</Position> */}
    </Item>
    // </StyledLink>
  );
};

export default MemberComponent;
