import React, { useState, useEffect } from "react";
import styled from "styled-components";

import image1 from "../../../Icons/Avtar/yoga1.png";
import Data from "../../../apiData/yoga2.json";
import RogyogComponent from "./RogyogComponent";
import PageTitle from '../../common/PageTitle';
import { useParams } from "react-router-dom";

const Section = styled.section`
position: relative;
  background-image: linear-gradient( ${(props) => props.theme.lightBlueShade},  ${(props) => props.theme.yellowShade});
  margin-top: -17px;
}
`;

const Title = styled.h1`
  // background-color: red;

  font-size: ${(props) => props.theme.fontxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.buttonColor};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  width: fit-content;
  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontlg};
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
  }
  }
`;
const Subtitle = styled.h1`

padding: 5px 12% 15px 12%;
font-size: ${(props) => props.theme.fontmd};
text-align:center;
// font-weight:700;
// display: flex;
// align-items: center;
// justify-content: center;
text-transform: camelcase;
color: ${(props) => props.theme.buttonColor};
@media (max-width: 64em) {
  font-size: ${(props) => props.theme.fontmd};
  text-align: center;
}
@media (max-width: 48em) {
  font-size: ${(props) => props.theme.fontsm};
  text-align: justify;
}
@media (max-width: 30em) {
  font-size: ${(props) => props.theme.fontxs};
  text-align: justify;
}
`;

const Container = styled.div`
  margn-bottom:0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const Wave = styled.div`
  display: block;
  position: relative;
  height:70px;
  width: 100%;
  background-color: ${(props) => props.theme.waveLightBlue};;
  // background-color: yellow;

  transform: scale(1, 1.5);
  
  &::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    height: 300px;
     background-color: ${(props) => props.theme.waveLightBlue};;
    //  background-color: red;

    width: 88%;

    left: -30%;
    top: -14px;
    clip-path: ellipse(70% 30% at 80% -7%); 

}

&::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 110%;
  height: 300px;
  background-color: white;

  right: -25%;
  top: -260px;
   clip-path: ellipse(100% 20% at 35% 100%); 
}

`

const Wave2 = styled.div`
  display: block;
  position: relative;
  height: 40px;
  width: 100%;
  background-color:  ${(props) => props.theme.yellowShade};
  transform: scale(1, 1.5);
  
  &::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 300px;
  background-color: white;
  right: -25%;
  top: 20px;
}

&::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 300px;
  background-color:  ${(props) => props.theme.yellowShade};
  left: -25%;
  top: -240px;
  clip-path: ellipse(100% 15% at -15% 100%);
}

`

const RogyogCat = ({isUpeerWave,isLowerWave}) => {
  console.log(" IN RogyogCat");

  const { category } = useParams();
  const [categoryData, setCategoryData] = useState([]);
  useEffect(() => {
    const filterCategoryData = Data?.filter(
      (value, index) => value.sN == category
    );
    setCategoryData(filterCategoryData[0]);
  }, [category]);
  console.log(category);

  const renderTile = (singleData, index) => {
    const tileData = {
      name: singleData.subCatName,
      description: singleData.subCatDescription,
      subcat: singleData.subSubCat,
      ...singleData,
    };
    return (
      <RogyogComponent
        renderKey={`${singleData.subCatName}_${index}`}
        img={image1}
        tileData={tileData}
        link={`/rogyog/${category}/${singleData.sN}`}
      />
    );
  };

  return (
    <Section id="navigation">
           {isUpeerWave ?  <Wave/>:<></>}
      <PageTitle title={categoryData.category} />
      <Title>{categoryData.category}</Title>
      <Subtitle>{categoryData.categoryDescription}</Subtitle>
      <Container>{categoryData?.subcat?.map(renderTile)}</Container>
      {isLowerWave ?  <Wave2/>:<></>}
    </Section>
  );
};

export default RogyogCat;
