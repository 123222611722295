import React from "react";
import styled, { ThemeProvider } from "styled-components";
import Carousel from "../../common/Carousel";
import ButtonOrange from "../../common/ButtonOrange";
import { dark } from "../../../styles/Themes";
import { useNavigate } from "react-router-dom";

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;
  // padding-bottom: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 70em) {
    width: 85%;
  }
  @media (max-width: 64em) {
    margin: 40px auto;

    width: 100%;
    flex-direction: column;
    & > *:last-child {
      width: 80%;
    }
  }
  @media (max-width: 40em) {
    margin: 40px auto;

    & > *:last-child {
      width: 90%;
    }
  }
`;

const Box = styled.div`
  width: 50%;
  height: 100%;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 40em) {
    min-height: 50vh;
  }
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.TextColorBlack};
  align-self: flex-start;
  width: 80%;
  margin: 0 auto;
  @media (max-width: 64em) {
    margin-top: 40px ;
    width: 100%;
    text-align: center;
  }
  @media (max-width: 40em) {
    margin-top: 40px ;
    font-size: ${(props) => props.theme.fontxl};
  }
  @media (max-width: 30em) {
    margin-top: 40px ;

    font-size: ${(props) => props.theme.fontlg};
  }
`;
const SubTitle = styled.h3`
  font-size: ${(props) => props.theme.fontxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.darkGreen};
  font-weight: 600;
  align-self: flex-start;
  width: 80%;
  margin: 0 auto;
  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
  }
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontlg};
  }
`;

const SubText = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.TextColorBlack};
  align-self: flex-start;
  width: 80%;
  margin: 1rem auto;
  font-weight: 400;
  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontmd};
  }
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
    text-align: justify;
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
    text-align: justify;

  }
`;
const SubTextLight = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => props.theme.TextColorBlack};
  align-self: flex-start;
  width: 80%;
  margin: 1rem auto;
  font-weight: 400;
  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontsm};
  }
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontsm};
    text-align: justify;

  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontxs};
    text-align: justify;

  }
`;
const ButtonContainer = styled.div`
  width: 80%;
  display: flex;
  align-self: flex-start;
  margin: 1rem auto;

  @media (max-width: 64em) {
    width: 100%;
    button {
      margin: 0 auto;
    }
  }
`;

const About = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/contactUs");
  };
  return (
    <Section id="about">
      <Container>
        <Box>
          {" "}
          <Carousel />
        </Box>
        <Box>
          <Title>
            Welcome To The <br />
          </Title>
          <SubTitle>YOGA SHASTRA</SubTitle>
          <SubText>
            The aim of YOGA SHASTRA is to cultivate physical and mental health,
            achieve inner peace, and connect with the deeper aspects of the
            self. It aims to integrate the mind, body, and spirit, and to bring
            harmony and balance to all aspects of our being{" "}
          </SubText>
          <SubTextLight>
            While practicing yoga can have numerous benefits for both physical
            and mental health, having a yoga trainer can help individuals to
            develop proper techniques, avoid injury, and progress in their
            practice. A skilled yoga trainer can also provide personalized
            instruction and modifications based on an individual's needs, goals,
            and limitations, making yoga accessible and beneficial for people of
            all levels and abilities.
          </SubTextLight>
          <ButtonContainer>
            <ThemeProvider theme={dark}>
              <ButtonOrange text="Book a Trial" action={() => handleClick()} />
            </ThemeProvider>
          </ButtonContainer>
        </Box>
      </Container>
    </Section>
  );
};

export default About;
