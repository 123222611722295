import React, { Component } from "react";
import Slider from "react-slick";
import utils from "../../common/utils";
import styled, { keyframes } from "styled-components";
import sliderJson from "../../../apiData/slider.json";
import background from "../../../assets/BG7.jpg";
import slider_bg from "../../../assets/slider-bg.png";
import ButtonOrange from "../../common/ButtonOrange";
import { useNavigate } from "react-router-dom";
const MainContainer = styled.div`
  height: 75vh;
  // background:green;
  @media (max-width: 50em) {
    height: 70vh;

  }
`;
const Container0 = styled.div`
// background:orange;
display: flex;
height:100%;
flex-direction: row-reverse;
justify-content:center;
@media (max-width: 30em) {
  flex-direction: column-reverse;
}

`
const Container2 = styled.div`
  position: relative;
  width: 50%;
  // padding: 16px;
  // background:yellow;
  display: flex;
  flex-direction: column;
  justify-content:center;
  @media (max-width: 30em) {
    // margin: 0px 0px 35px;
    width: 100%;
  }
`;

const MainDiv = styled.div`
// background:url(${background});
// background-size: cover;
background-image: linear-gradient(   ${(props) => props.theme.lightBlueShade},${(props) => props.theme.yellowShade});



`;
const Wave = styled.div`
  display: block;
  position: relative;
  height:70px;
  width: 100%;
  background-color: ${(props) => props.theme.waveLightBlue};;
  // background-color: yellow;

  transform: scale(1, 1.5);
  
  &::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    height: 300px;
     background-color: ${(props) => props.theme.waveLightBlue};;
    //  background-color: red;

    width: 88%;

    left: -30%;
    top: -14px;
    clip-path: ellipse(70% 30% at 80% -7%); 

}

&::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 110%;
  height: 300px;
  background-color: white;

  right: -25%;
  top: -260px;
   clip-path: ellipse(100% 20% at 35% 100%); 
}

`

const Wave2 = styled.div`
  display: block;
  position: relative;
  height: 40px;
  width: 100%;
  background-color:  ${(props) => props.theme.yellowShade};
  transform: scale(1, 1.5);
  // background-color: red;
  &::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 300px;
  background-color: white;
  right: -25%;
  top: 20px;
}

&::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 300px;
  background-color:  ${(props) => props.theme.yellowShade};
  left: -25%;
  top: -240px;
  clip-path: ellipse(100% 15% at -15% 100%);
}

`
const PhotoContainer = styled.div`
display: flex;
height: 50vh;
width: 50vh;
background: ${(props) => props.theme.lightGreen};
background:url(${slider_bg});
background-size: cover;
// background-gray;
justify-content:center;
algin-item:center;
align-self: center;
content: "";
animation: spin 17s linear infinite;
@keyframes spin {
   0% {
      transform: rotate(0deg);
   }
   100% {
      transform: rotate(360deg);
   }
}

@media (max-width: 50em) {
  height: 35vh;
  width: 35vh;
}

  }
`;

const Container4 = styled.div`
// width:50%;
// background:red;
margin: auto;
text-align:center;
`;

const Photo1 = styled.img`
  height: 50vh;
  width: auto;
  // max-height:100%; max-width:100%;
  margin-lefft
  justify-content:center;
  algin-item:center;
  animation: spin2 17s linear infinite;
  @keyframes spin2 {
     100% {
        transform: rotate(0deg);
     }
     0% {
        transform: rotate(360deg);
     }
  }
  @media (max-width: 50em) {
    height: 35vh;
    width: auto;
  }
`;

const Profession = styled.div`
  line-height: 1.5;
  overflow-wrap: break-word;
  text-align: center;
  color: ${(props) => props.theme.buttonColor};
  position: relative;
  font-size: 21px;
  @media (max-width: 50em) {
    font-size: 11px;
  }
`;

const Name = styled.p`
color: ${(props) => props.theme.buttonColor};
font-size: 63px;
  text-align: center;
  font-weight: bold;
  @media (max-width: 50em) {
    font-size: 40px;
  }
`;

const Testimonial = styled.p`
color: ${(props) => props.theme.buttonColor};
text-align: center;
  
  font-size: 0.9rem;

`;
const Hi = styled.p`
color: ${(props) => props.theme.buttonColor};
font-size: 40px;
text-align: center;
font-weight: bold;
@media (max-width: 50em) {
  font-size: 20px;
}

`;


const NftItem = (value, index) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/contactUs");
  };
  return (
    <MainContainer key={`${index}`}>
      <Container0>
        <PhotoContainer>
          <Photo1 src={value.image} alt={value?.name} />
        </PhotoContainer>
        <Container2>
          <Container4>
            <div>{index == 0 ? (<Hi>Hi, I'm</Hi>) : <h1></h1>}
            </div>
            <Name>{value?.name}</Name>
            <Profession>{value?.profession}</Profession>
            <Testimonial >
              {utils.getShortDesc(value?.comments)}
            </Testimonial>
            <div>{index != 0 ? (<ButtonOrange text="Request A Free Consultation" action={() => handleClick()} />) : <h1></h1>}
            </div>
            

          </Container4>
          
        </Container2>
      </Container0>
    </MainContainer>
    
  );
};

function SliderComponent() {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    lazyLoad: true,
    autoplay:true,
    dots: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    afterChange: function (index) {
      console.log(
        `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
      );
    }
  };

  return (
    <MainDiv >
      {/* <img src={background}/> */}
      <Slider {...settings}>
        {sliderJson?.map(NftItem)}
      </Slider>
      <Wave2/>
    </MainDiv>
  )
}

export default SliderComponent