import React, { useState, useEffect } from "react";
import styled from "styled-components";

import image1 from "../../../Icons/Avtar/yoga1.png";
import Data from "../../../apiData/yoga2.json";
import { useParams } from "react-router-dom";
import PageTitle from '../../common/PageTitle';
import RogyogComponenHorizontoal from "./RogyogComponenHorizontoal";
import DetailComponentRogyog from "./DetailComponentRogyog";

const Section = styled.section`
  position: relative;
  background-image: linear-gradient( ${(props) => props.theme.lightBlueShade},  ${(props) => props.theme.yellowShade});
  margin-top: -7px;
`;

const Title = styled.h1`
  // background-color: red;
padding: 30px 10px;
font-size: ${(props) => props.theme.fontxl};
font-weight:700;
display: flex;
align-items: center;
justify-content: center;
text-transform: uppercase;
color: ${(props) => props.theme.TextColorBlack};
@media (max-width: 64em) {
  font-size: ${(props) => props.theme.fontxl};
  text-align: center;
  width: 100%;
}
@media (max-width: 48em) {
  font-size: ${(props) => props.theme.fontlg};
  padding: 1rem 0;
  text-align: center;
  width: 100%;
}
@media (max-width: 30em) {
  font-size: ${(props) => props.theme.fontsm};
  padding-top: 10px;
}
`;

const SubTitle = styled.h1`
font-size: ${(props) => props.theme.fontmd};
display: flex;
align-items: center;
justify-content: center;
text-transform: camelcase;
text-align:center;
color: ${(props) => props.theme.TextColorBlack};
margin-top: 1rem;
@media (max-width: 64em) {
  font-size: ${(props) => props.theme.fontmd};
  text-align: center;
  padding: 0px 18px;
}
}
@media (max-width: 48em) {
  font-size: ${(props) => props.theme.fontsm};
  text-align: justify;
  padding: 0px 18px;
}
}
@media (max-width: 30em) {
  font-size: ${(props) => props.theme.fontxs};
  text-align: justify;
  padding: 0px 18px;
}
}


// background-color:red;

`;

const Container = styled.div`
  margin: 2rem auto;
  width: 40%;
  @media (max-width: 75em) {
    flex-direction: row;
    width: 80%;
    justify-content: left;
    align-self: flex-start;
  }
  @media (max-width: 48em) {
    flex-direction: row;
    width: 90%;
    justify-content: center;
  }
`;
const ContainerSide = styled.div`
  width: 80%;
  flex-direction: column;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  flex: 1;
  @media (max-width: 64em) {
    width: 80%;
  }
  @media (max-width: 48em)
   {
    width: 90%;
    justify-content: center;
  }
`;

const TitleSubContainer = styled.div`
  width: 90%;
  margin: 0rem auto;
  // display: flex;
  // flex-wrap: wrap;
  text-align:Center;
  @media (max-width: 64em) {
    width: 100%;
  }
  @media (max-width: 48em) {
    width: 100%;
    justify-content: center;
  }
`;
const MainContainer = styled.div`
  width: 90%;
  margin: 0rem auto;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 64em) {
    width: 100%;
  }
  @media (max-width: 48em) {
    width: 100%;
    justify-content: center;
  }
`;
const Wave = styled.div`
  display: block;
  position: relative;
  height: 40px;
  width: 100%;
  background-color:  ${(props) => props.theme.yellowShade};
  transform: scale(1, 1.5);


  
  &::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 300px;
  background-color: white;
  right: -25%;
  top: 20px;
}

&::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 300px;
  background-color:  ${(props) => props.theme.yellowShade};
  left: -25%;
  top: -240px;
  clip-path: ellipse(100% 15% at -15% 100%);
}

`
const RogyogSubCat = () => {
  const { category } = useParams();
  const [subCategoryName, setSubCategoryName] = useState("");
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [subSubSelectedCategoryData, setsubSubSelectedCategoryData] = useState([]);

  useEffect(() => {
    const pathName = window.location.pathname;
    let subCategory = pathName.split("/")[3];
    var subSubCategoryDetailId = pathName.split("/")[4];

    console.log(category);

    console.log(subCategory);
    let filterCategoryData = Data?.filter(
      (value, index) => value.sN == category
    );
    console.log(filterCategoryData);
    filterCategoryData = filterCategoryData[0]?.subcat?.filter(
      (value, index) => value.sN == subCategory
    );
    console.log('****', filterCategoryData);

    if (subSubCategoryDetailId === undefined) {
      subSubCategoryDetailId = filterCategoryData[0]?.subSubCat[0]?.sN;
    }
    let filterCategoryDetailData = filterCategoryData[0]?.subSubCat?.filter(
      (value, index) =>
        value.sN.toLowerCase() == subSubCategoryDetailId.toLowerCase()
    );
    console.log('filterCategoryDetailData', filterCategoryDetailData);
    setsubSubSelectedCategoryData(filterCategoryDetailData);

    setSubCategoryData(filterCategoryData[0]);
    setSubCategoryName(subCategory);
  }, [category, window.location.pathname]);


  const renderTileSlected = (singleData, index) => {
    console.log('single Data', singleData);
    const tileData = {
      name: singleData.subCatName,
      description: singleData.description,
      instruction: singleData.instruction,
      benifits: singleData.benifits,
      concequences: singleData.concequences,
      ...singleData,
    };
    return (
      <DetailComponentRogyog
        renderKey={`${singleData.subCatName}_${index}`}
        img={image1}
        tileData={tileData}
      />
    );
  };


  const renderTile = (singleData, index) => {
    const tileData = {
      name: singleData.subCatName,
      description: singleData.description,
      asnVideoPath: singleData.asnVideoPath,
      instruction: singleData.instruction,
      benifits: singleData.benifits,
      concequences: singleData.concequences,
      ...singleData,
    };

    return (
      <RogyogComponenHorizontoal
        setsubSubSelectedCategoryData={setsubSubSelectedCategoryData}
        renderKey={`${singleData.subCatName}_${index}`}
        img={image1}
        tileData={tileData}
      // link={`/rogyog/${category}/${subCategoryName}/${singleData.sN}`}
      />
    );
  };

  console.log('subCategoryData ====>', subCategoryData);
  console.log('subSubSelectedCategoryData ====>', subSubSelectedCategoryData)


  return (
    <Section id="navigation">
      <PageTitle title={` ${subCategoryData.subCatName}`} />
      <TitleSubContainer>
        <Title>{` ${subCategoryData.subCatName}`}</Title>
        {/* <SubTitle>{` ${subCategoryData.subCatDescription}`}</SubTitle> */}
      </TitleSubContainer>
      <MainContainer>
        <ContainerSide>{subSubSelectedCategoryData?.map(renderTileSlected)}</ContainerSide>
        <Container>{subCategoryData?.subSubCat?.map(renderTile)}</Container>
      </MainContainer>
      <Wave/>
    </Section>
  );
};

export default RogyogSubCat;
