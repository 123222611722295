import React from "react";
import styled from "styled-components";
import Typewriter from "typewriter-effect";
import Button from "../common/Button";
import { useNavigate } from "react-router-dom";

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxl};
  text-transform: capitalize;
  width: 80%;
  color: ${(props) => props.theme.text};
  align-self: flex-start;

  span {
    text-transform: uppercase;
    font-family: "Akaya Telivigala", cursive;
  }
  .text-1 {
    color: blue;
  }
  .text-2 {
    color: orange;
  }
  .text-3 {
    color: red;
  }
  .text-4 {
    color: green;
  }

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxl};
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontlg};
    align-self: center;
    text-align: center;
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontmd};
    width: 90%;
    align-self: center;
    text-align: center;
  }
`;
const SubTitle = styled.h3`
  font-size: ${(props) => props.theme.fontxs};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  font-weight: 300;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  width: 100%;
  align-self: flex-start;
  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxs};
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontsm};
    align-self: center;
    text-align: center;
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  align-self: flex-start;
  height: 30%;

  @media (max-width: 48em) {
    align-self: center;
    text-align: center;
    button {
      margin: 0 auto;
      margin-bottom: 0.5rem;
    }
  }
`;

const TypeWriterText = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/contactUs");
  };

  return (
    <Title>
      <Typewriter
        options={{
          autoStart: true,
          loop: true,
        }}
        onInit={(typewriter) => {
          typewriter
            .typeString('<span class="text-1">Mindfulness.</span>')
            .pauseFor(2000)
            .deleteAll()
            .typeString('<span class="text-2">Strength.</span>')
            .pauseFor(2000)
            .deleteAll()
            .typeString('<span class="text-3">Flexibility.</span>')
            .pauseFor(2000)
            .deleteAll()
            .typeString('<span class="text-1">Relaxation.</span>')
            .pauseFor(2000)
            .deleteAll()
            .typeString('<span class="text-2">Balance.</span>')
            .pauseFor(2000)
            .deleteAll()
            .typeString('<span class="text-3">Harmony.</span>')
            .pauseFor(2000)
            .deleteAll()
            .typeString('<span class="text-3">Wellness.</span>')
            .pauseFor(2000)
            .deleteAll()
            .typeString('<span class="text-1">Energy.</span>')
            .pauseFor(2000)
            .deleteAll()
            .typeString('<span class="text-2">Serenity.</span>')
            .pauseFor(2000)
            .deleteAll()
            .typeString('<span class="text-3">Inner peace.</span>')
            .pauseFor(2000)
            .deleteAll()
            .start();
        }}
      />

      <SubTitle>
        Harmonize your mind, body, and soul from anywhere with our online yoga
        classes.
      </SubTitle>
      <ButtonContainer>
        <Button
          text="Book a Trial"
          link="#about"
          action={() => handleClick()}
        />
      </ButtonContainer>
    </Title>
  );
};

export default TypeWriterText;
