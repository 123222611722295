import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Data from "../../../apiData/yoga.json";
import MemberComponent from "./MemberComponent";
import { useParams } from "react-router-dom";

const Section = styled.section`
  background-color: ${(props) => props.theme.body};
  margin-top: -17px;
    background-image: linear-gradient(   ${(props) => props.theme.lightBlueShade},${(props) => props.theme.yellowShade});


`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.buttonColor};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  width: fit-content;
  padding-top: 30px;
  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontlg};
    padding-top: 15px;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
    padding-top: 13px;
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
    padding-top: 10px;
  }
  `;



const Description = styled.h1`
font-size: 0.9rem;
padding: 10px 12% 45px 12%;
line-height: 1.5;
overflow-wrap: break-word;
text-align: center;
color: ${(props) => props.theme.TextColorBlack};
@media (max-width: 64em) {
  font-size: ${(props) => props.theme.fontmd};
  text-align: center;
}
@media (max-width: 48em) {
  font-size: ${(props) => props.theme.fontsm};
  text-align: justify;
}
@media (max-width: 30em) {
  font-size: ${(props) => props.theme.fontxs};
  text-align: justify;
}
`;

const Container = styled.div`
width: 75%;
margin: .3rem auto;
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
@media (max-width: 64em) {
  // width: 80%;
}
@media (max-width: 48em) {
  // width: 90%;
  justify-content: center;
}
`;

const Wave = styled.div`
  display: block;
  position: relative;
  height:70px;
  width: 100%;
  background-color: ${(props) => props.theme.waveLightBlue};;
  // background-color: yellow;

  transform: scale(1, 1.5);
  
  &::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    height: 300px;
     background-color: ${(props) => props.theme.waveLightBlue};;
    //  background-color: red;

    width: 88%;

    left: -30%;
    top: -14px;
    clip-path: ellipse(70% 30% at 80% -7%); 

}

&::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 110%;
  height: 300px;
  background-color: white;

  right: -25%;
  top: -260px;
   clip-path: ellipse(100% 20% at 35% 100%); 
}

`

const Wave2 = styled.div`
  display: block;
  position: relative;
  height: 40px;
  width: 100%;
  background-color:  ${(props) => props.theme.yellowShade};
  transform: scale(1, 1.5);
  
  &::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 300px;
  background-color: white;
  right: -25%;
  top: 20px;
}

&::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 300px;
  background-color:  ${(props) => props.theme.yellowShade};
  left: -25%;
  top: -240px;
  clip-path: ellipse(100% 15% at -15% 100%);
}

`

const Category = ({isUpeerWave,isLowerWave}) => {

  const { category } = useParams();
  const [categoryData, setCategoryData] = useState([]);
  const [categoryName, setCategoryName] = useState([]);
  const [categoryDescription, setCategoryDescription] = useState("");
 


  useEffect(() => {
    const filterCategoryData = Data?.filter(
      (value, index) => value.sN.toLowerCase() == category.toLowerCase()
    );
    // console.log('first',filterCategoryData);
    if (filterCategoryData.length>0){
      setCategoryName(filterCategoryData[0].category);
      setCategoryDescription(filterCategoryData[0].description);
      
      setCategoryData(filterCategoryData[0]);
    }
    
  }, [category]);
  // console.log(category);
  const renderTile = (singleData, index) => {
    var ROOT_PATH = process.env.PUBLIC_URL+`/assets/yoga`;
    const tileData = {
      sN:singleData.sN,
      name: singleData.subCatName,
      ...singleData,
    };
    return (
      <MemberComponent
        renderKey={`${singleData.subCatName}_${index}`}
        img={`${ROOT_PATH}/${singleData.img}`}
        tileData={tileData}
        link={`/${category}/${singleData.sN}`}
        category = {` ${singleData.sN}`}
        dataArray = {singleData.subSubCat}
        description={singleData.description}
      />
    );
  };

  return (
    <Section id="navigation">
      {isUpeerWave ?  <Wave/>:<></>}
      <Title>{categoryName}</Title>
      <Description>{categoryDescription}</Description>
      <Container>{categoryData?.subcat?.map(renderTile)}</Container>
      {isLowerWave ?  <Wave2/>:<></>}
    </Section>
  );
};

export default Category;
