import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";


const Item = styled.div`
  height:14rem;
  display: flex;
  flex-direction:row;
  padding: 1rem 0;
  color: ${(props) => props.theme.body};
  margin: 1rem 1rem;
  position: relative;
  z-index: 5;
  backdrop-filter: blur(4px);
  background-color: ${(props) => props.theme.carouselColor};
  border: 1px solid ${(props) => props.theme.TextColorBlack};
  background-image: linear-gradient(   ${(props) => props.theme.lightBlueShade},${(props) => props.theme.yellowShade});

  border-radius: 20px;
  transition: all 0.3s ease;

  &:hover {
    img {
      // transform: translateY(-2rem) scale(1.2);
    }
  }
  @media (max-width: 48em) {
    flex-direction:column;
    height:auto;
    padding: 0rem 0;

  }

  @media (max-width: 30em) {
    flex-direction:column;
    height:auto;
    padding: 0rem 0;

  }

`;

const ImageContainer = styled.div`
margin: 5px 5px 5px 20px;
height:100%;
background-color: ${(props) => props.theme.carouselColor};
border: 1px solid ${(props) => props.theme.buttoncolorO};
border-radius: 5px;
cursor: pointer;
align-self:center;
// width : 11rem;
overflow:hidden;
object-fit: fill;

img {
  max-height:100%;
  display: block;
margin-left: auto;
margin-right: auto;
  object-fit: fill;
  border-radius:5px;
  transition: all 0.3s ease;
  @media (max-width: 48em) {
    width:100%;
    
  }

  @media (max-width: 30em) {

    width:100%;
  }
}

@media (max-width: 48em) {
  margin: 0px 0px 0px 0px;
  background-color:transparent;
  border-radius: 20px;
  border: 0px solid ${(props) => props.theme.TextColorBlack};

}

@media (max-width: 30em) {
  margin: 0px 0px 0px 0px;
  background-color:transparent;
  border-radius: 20px;
  border: 0px solid ${(props) => props.theme.TextColorBlack};

}
}
`;

const Name = styled.h2`
font-size: ${(props) => props.theme.fontmd};
// display: flex;
// align-items: left;
// justify-content: left;
font-weight:700;
max-width: 30%
text-transform: uppercase;
color: ${(props) => props.theme.TextColorBlack};
// margin-top: 1rem;
display: inline;
-webkit-line-clamp: 1;
text-overflow: ellipsis;
overflow: hidden;
display: -webkit-box;
-webkit-box-orient: vertical;
word-wrap: break-word;
`;
const Description = styled.h2`
font-size: ${(props) => props.theme.fontsm};
display: flex;
text-align: justify;
align-items: left;
justify-content: left;
color: ${(props) => props.theme.textRgba};
color: ${(props) => props.theme.TextColorBlack};
// max-width:30%;
margin-top: 1rem;
display: inline;
-webkit-line-clamp: 7;
text-overflow: ellipsis;
overflow: hidden;
display: -webkit-box;
-webkit-box-orient: vertical;
word-wrap: break-word;
@media (max-width: 48em) {
  -webkit-line-clamp: 4;
  font-size: ${(props) => props.theme.fontxs};

}

@media (max-width: 30em) {
  -webkit-line-clamp: 3;
  font-size: ${(props) => props.theme.fontxs};

}
`;
const Position = styled.h2`
  font-size: ${(props) => props.theme.fontmd};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${(props) => `rgba(${props.theme.textRgba},0.9)`};
  margin-top: 1rem;
  font-weight: 400;
`;
const TextContainer = styled.div`
width: 68%;;
word-wrap: break-word;
// background-color:yellow;
margin: 2px;
padding: 0.5rem;
display: flex;
flex-direction:column;
@media (max-width: 48em) {
  width:100%;

}

@media (max-width: 30em) {
  width:100%;

}
  
`;

const VideoTime = styled.div`
  transform: translate(-3.5px, -18.5px);
  background-color:black;
  width:32px;
  border-radius:5px;
  font-size:10px;
  font-weight:Bold;
  padding-left:5px;
  float:right;
`;


const RogyogComponenHorizontoal = (props) => {
  return (
      <Item id="item" onClick={()=>{
        props.setsubSubSelectedCategoryData([{...props.tileData}]);
      }}>
        <ImageContainer>
          <img id="avatar" src={`https://img.youtube.com/vi/${props.tileData.asnVideoPath}/maxresdefault.jpg`} alt={props.tileData.name} />
          <VideoTime>{props.tileData.asnVideoDuration}</VideoTime>
        </ImageContainer>
        <TextContainer>
          <Name>{props.tileData.name}</Name>
        <Description>{props.tileData.description}</Description>
        </TextContainer>
      </Item>
  ) 
};

export default RogyogComponenHorizontoal;
