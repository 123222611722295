import React,{useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import ReactGA from 'react-ga4';


const MainBox = styled.div`
widht: 100%;
padding-inline: 8%;
padding-block: 3%;

`
const ContentBox = styled.div`
// padding-block: 3%;

`
const Heading = styled.h1`
// font-size: ${(props) => props.theme.fontxxl};
font-size: 2.6em;
color: #0C5512;
padding-bottom: 2%;


`
const SubHeading = styled.h2`
padding-bottom: 1%;
font-size: 2.2rem;
line-height: 3rem;
// letter-spacing: 0.01rem;
color: #FA8614;

`
const SubSubHeading = styled.h3`
font-size: 2rem;
line-height: 3rem;
color: #389E00;

`
const Paragraph = styled.p`
// padding-block: 2%;
line-height: 30px;
color: #213D1A;
text-align: justify;


`


function CookiePolicy() {
  return (
    <MainBox>
    <ContentBox>
        <Heading>Cookie Policy</Heading>
        <Paragraph>
            This cookie policy (“Policy”) describes what cookies are and how and they're being used by the <span style={{color: "green"}}>ino@yoga-shastra.com</span> website (“Website” or “Service”) and any of its related products and services (collectively, “Services”). This Policy is a legally binding agreement between you (“User”, “you” or “your”) and Bodhi School of Yoga (“Bodhi School of Yoga”, “we”, “us” or “our”). If you are entering into this agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this agreement, in which case the terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this agreement, you must not accept this agreement and may not access and use the Website and Services. You should read this Policy so you can understand the types of cookies we use, the information we collect using cookies, and how that information is used. It also describes the choices available to you regarding accepting or declining the use of cookies. For further information on how we use, store and keep your personal data secure, see our privacy policy.
        </Paragraph>

        <hr />

        <SubHeading>What are cookies?</SubHeading>
        <Paragraph>Cookies are small pieces of data stored in text files that are saved on your computer or other devices when websites are loaded in a browser. They are widely used to remember you and your preferences, either for a single visit (through a "session cookie") or for multiple repeat visits (using a "persistent cookie"). <br /> <br />

        Session cookies are temporary cookies that are used during the course of your visit to the Website, and they expire when you close the web browser. <br /> <br />
        
        Persistent cookies are used to remember your preferences within our Website and remain on your desktop or mobile device even after you close your browser or restart your computer. They ensure a consistent and efficient experience for you while visiting the Website and Services. <br /> <br />
        
        Cookies may be set by the Website ("first-party cookies"), or by third parties, such as those who serve content or provide advertising or analytics services on the Website ("third party cookies"). These third parties can recognize you when you visit our website and also when you visit certain other websites. You may learn more about cookies and how they work in this guide.</Paragraph>



    </ContentBox>
    <ContentBox>
    <SubHeading>What type of cookies do we use?</SubHeading>
    <SubSubHeading>Necessary cookies
    </SubSubHeading>
    <Paragraph>Necessary cookies allow us to offer you the best possible experience when accessing and navigating through our Website and using its features. For example, these cookies let us recognize that you have created an account and have logged into that account to access the content.</Paragraph>
    <SubSubHeading>Functionality cookies</SubSubHeading>
    <Paragraph>Functionality cookies let us operate the Website and Services in accordance with the choices you make. For example, we will recognize your username and remember how you customized the Website and Services during future visits.</Paragraph>
    <SubSubHeading>Analytical cookies</SubSubHeading>
    <Paragraph>These cookies enable us and third-party services to collect aggregated data for statistical purposes on how our visitors use the Website. These cookies do not contain personal information such as names and email addresses and are used to help us improve your user experience of the Website.</Paragraph>
    <SubSubHeading>Advertising cookies</SubSubHeading>
    <Paragraph>Advertising cookies allow us and third parties to serve relevant ads to you more effectively and help us collect aggregated audit data, research, and performance reporting for advertisers. They also enable us to understand and improve the delivery of ads to you and know when certain ads have been shown to you. <br /><br />

    Your web browser may request advertisements directly from ad network servers, these networks can view, edit, or set their own cookies, just as if you had requested a web page from their website. <br /><br />
    
    Although we do not use cookies to create a profile of your browsing behavior on third-party websites, we do use aggregate data from third parties to show you relevant, interest-based advertising.</Paragraph>
    <SubSubHeading>Social media cookies</SubSubHeading>
    <Paragraph>Third-party cookies from social media sites (such as Facebook, Twitter, etc) let us track social network users when they visit or use the Website and Services, or share content, by using a tagging mechanism provided by those social networks. <br /><br />

    These cookies are also used for event tracking and remarketing purposes. Any data collected with these tags will be used in accordance with our and social networks’ privacy policies. We will not collect or share any personally identifiable information from the user.</Paragraph>


    <hr />
    </ContentBox>
    <ContentBox>
    <SubHeading>Do we use web beacons or tracking pixels?</SubHeading>
    <Paragraph>Our emails may contain a "web beacon" (or "tracking pixel") to tell us whether our emails are opened and verify any clicks through to links or advertisements within the email. <br /><br />

    We may use this information for purposes including determining which of our emails are more interesting to users and to query whether users who do not open our emails wish to continue receiving them. <br /><br />
    
    The pixel will be deleted when you delete the email. If you do not wish the pixel to be downloaded to your device, you should read the email in plain text view or with images disabled.</Paragraph> <hr />
    <SubHeading>What are your cookie options?</SubHeading>
    <Paragraph>If you don’t like the idea of cookies or certain types of cookies, you can change your browser’s settings to delete cookies that have already been set and to not accept new cookies. To learn more about how to do this, visit internetcookies.com</Paragraph><hr />
    <SubHeading>Changes and amendments</SubHeading>
    <Paragraph>We reserve the right to modify this Policy or its terms related to the Website and Services at any time at our discretion. When we do, we will revise the updated date at the bottom of this page. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided. <br /><br />

    An updated version of this Policy will be effective immediately upon the posting of the revised policy unless otherwise specified. Your continued use of the Website and Services after the effective date of the revised Policy (or such other act specified at that time) will constitute your consent to those changes.</Paragraph><hr />
    <SubHeading>Acceptance of this policy</SubHeading>
    <Paragraph>You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and using the Website and Services you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use the Website and Services.</Paragraph><hr />
    <SubHeading>Contacting us</SubHeading>
    <Paragraph>If you have any questions, concerns, or complaints regarding this Policy or the use of cookies, we encourage you to contact us using the details below: <br /><br />

    <span style={{color:"green"}}>ino@yoga-shastra.com</span> <br /><br />
      
    
    This document was last updated on 28th, September, 2023</Paragraph>
 
    
    </ContentBox>


</MainBox>
  )
}

export default CookiePolicy
