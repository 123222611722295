import React from "react";
import styled from "styled-components";
import Data from "../../../apiData/yoga.json";
import MemberComponent from "./MemberComponent";

const Section = styled.section`
  background-image: linear-gradient(   ${(props) => props.theme.lightBlueShade},${(props) => props.theme.yellowShade});
  margin-top: -17px;
  // background-color:yellow;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.buttonColor};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  width: fit-content;
  padding-top: 30px;
  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontlg};
    padding-top: 15px;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
    padding-top: 13px;
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
    padding-top: 10px;
  }
`;
const Description = styled.h1`
font-size: ${(props) => props.theme.fontmd};
padding: 10px 12% 0px 12%;
line-height: 1.5;
overflow-wrap: break-word;
text-align: center;
color: ${(props) => props.theme.TextColorBlack};

@media (max-width: 64em) {
  font-size: ${(props) => props.theme.fontmd};
  text-align: center;
}
@media (max-width: 48em) {
  font-size: ${(props) => props.theme.fontsm};
  text-align: justify;
}
@media (max-width: 30em) {
  font-size: ${(props) => props.theme.fontxs};
  text-align: justify;
}
`;


const Container = styled.div`
width: 100%;
margin: .3rem auto;
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
@media (max-width: 64em) {
  width: 80%;
}
@media (max-width: 48em) {
  width: 90%;
  justify-content: center;
}
`;


const Wave = styled.div`
  display: block;
  position: relative;
  height:70px;
  width: 100%;
  background-color: ${(props) => props.theme.waveLightBlue};;
  // background-color: yellow;

  transform: scale(1, 1.5);
  
  &::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    height: 300px;
     background-color: ${(props) => props.theme.waveLightBlue};;
    //  background-color: red;

    width: 88%;

    left: -30%;
    top: -14px;
    clip-path: ellipse(70% 30% at 80% -7%); 

}

&::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 110%;
  height: 300px;
  background-color: white;

  right: -25%;
  top: -260px;
   clip-path: ellipse(100% 20% at 35% 100%); 
}

`

const Wave2 = styled.div`
  display: block;
  position: relative;
  height: 40px;
  width: 100%;
  background-color:  ${(props) => props.theme.yellowShade};
  transform: scale(1, 1.5);
  
  &::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 300px;
  background-color: white;
  right: -25%;
  top: 20px;
}

&::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 300px;
  background-color:  ${(props) => props.theme.yellowShade};
  left: -25%;
  top: -240px;
  clip-path: ellipse(100% 15% at -15% 100%);
}

`
const Yog =  ({isUpeerWave,isLowerWave}) => {
  const renderTile = (singleData, index) => {
    // console.log(singleData);
    var ROOT_PATH = process.env.PUBLIC_URL+`/assets/yoga`;
    const tileData = {
      name: singleData.category,
      ...singleData,
    };
    // console.log(tileData);

    return (
      <MemberComponent
        renderKey={`${singleData.category}_${index}`}
        img={`${ROOT_PATH}/${singleData.img}`}
        tileData={tileData}
        singleData = {singleData}
        dataArray = {singleData.subcat}
        componentHierarchy = 'cat'
        link={`/${singleData.sN}`}
        description={singleData.description}
      />
    );
  };
  return (
    <Section id="team">
      {isUpeerWave ?  <Wave/>:<></>}
      <Title>Benefits of Yog</Title>
      <Description>Yoga offers a multitude of benefits for both the body and mind. Through its practice, individuals can experience improved flexibility, increased strength, and enhanced balance and coordination. Moreover, yoga's focus on mindfulness and deep breathing promotes stress reduction and inner calm, leading to greater emotional well-being. Additionally, it fosters better posture and body awareness, reducing the risk of musculoskeletal problems. Overall, yoga is a holistic approach to wellness, promoting physical health, mental clarity, and a sense of inner peace.</Description>
      <Container>{Data?.map(renderTile)}</Container>
      {isLowerWave ?  <Wave2/>:<></>}

    </Section>
  );
};

export default Yog;
