import React, { useState, useEffect } from "react";
import styled from "styled-components";
import image1 from "../../../Icons/Avtar/yoga1.png";
import DetailComponent from "./DetailComponent";
import Data from "../../../apiData/yoga.json";
import MemberComponentHorizontal from "./MemberComponentHorizontal";
import PageTitle from '../../common/PageTitle';
import { useParams } from "react-router-dom";

const Section = styled.section`
   position: relative;
  background-image: linear-gradient( ${(props) => props.theme.lightBlueShade},  ${(props) => props.theme.yellowShade});
  margin-top: -7px;
`;

const Title = styled.h1`
  // background-color: red;
padding: 30px 10px;
font-size: ${(props) => props.theme.fontxl};
font-weight:700;
display: flex;
align-items: center;
justify-content: center;
text-transform: uppercase;
color: ${(props) => props.theme.TextColorBlack};
@media (max-width: 64em) {
  font-size: ${(props) => props.theme.fontxl};
  text-align: center;
  width: 100%;
}
@media (max-width: 48em) {
  font-size: ${(props) => props.theme.fontlg};
  padding: 1rem 0;
  text-align: center;
  width: 100%;
}
@media (max-width: 30em) {
  font-size: ${(props) => props.theme.fontsm};
  padding-top: 10px;
}
`;

const Container = styled.div`
margin: 2rem auto;
width: 40%;
// background:orange;
@media (max-width: 75em) {
  flex-direction: row;
  width: 80%;
  justify-content: left;
  align-self: flex-start;
}
@media (max-width: 48em) {
  flex-direction: row;
  width: 90%;
  justify-content: center;
}
`;
const ContainerSide = styled.div`
width: 90%;
flex-direction: column;
display: flex;
align-items: baseline;
flex-wrap: wrap;
flex: 1;
@media (max-width: 64em) {
  width: 80%;
}
@media (max-width: 48em)
 {
  width: 90%;
  justify-content: center;
}
`;
const MainContainer = styled.div`
width: 90%;
  margin: 0rem auto;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 64em) {
    width: 100%;
  }
  @media (max-width: 48em) {
    width: 100%;
    justify-content: center;
  }
`;
const Wave = styled.div`
  display: block;
  position: relative;
  height: 40px;
  width: 100%;
  background-color:  ${(props) => props.theme.yellowShade};
  transform: scale(1, 1.5);


  
  &::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 300px;
  background-color: white;
  right: -25%;
  top: 20px;
}

&::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 300px;
  background-color:  ${(props) => props.theme.yellowShade};
  left: -25%;
  top: -240px;
  clip-path: ellipse(100% 15% at -15% 100%);
}

`
const SubSubCategory = () => {
  // console.log("Data", Data);
  const { category } = useParams();
  const [subCategoryName, setSubCategoryName] = useState("");
  const [selectedObject, setSelectedObject] = useState();

  const [subSubCategoryName, setSubSubCategoryName] = useState("");
  const [subSubCategoryData, setSubSubCategoryData] = useState({});
  // const [subSubCategoryDetailId, setSubSubCategoryDetailId] = useState("");

  const [subSubSelectedCategoryData, setsubSubSelectedCategoryData] = useState([]);

  useEffect(() => {
    const pathName = window.location.pathname;
    let subCategory = pathName.split("/")[2];
    let subSubCategory = pathName.split("/")[3];
    var subSubCategoryDetailId = pathName.split("/")[4];

    let filterCategoryData = Data?.filter(
      (value, index) => value.sN.toLowerCase() == category.toLowerCase()
    );
    filterCategoryData = filterCategoryData[0]?.subcat?.filter(
      (value, index) =>
        value.sN.toLowerCase() == subCategory.toLowerCase()
    );
    filterCategoryData = filterCategoryData[0]?.subSubCat?.filter(
      (value, index) => value.sN.toLowerCase() == subSubCategory.toLowerCase()
    );

    if(subSubCategoryDetailId === undefined){
      subSubCategoryDetailId= filterCategoryData[0]?.subSubCat[0]?.sN;
      
    }
    let filterCategoryDetailData = filterCategoryData[0]?.subSubCat?.filter(
      (value, index) =>
        value.sN.toLowerCase() == subSubCategoryDetailId.toLowerCase()
    );

    console.log('sub Cub sub cat data----',filterCategoryData[0]);
    // setSubSubCategoryDetailId(filterCategoryData[0].sN);
    setsubSubSelectedCategoryData(filterCategoryDetailData);
    setSubSubCategoryData(filterCategoryData[0]);
    setSubCategoryName(subCategory);
    // setSubSubCategoryName(subSubCategory);
    setSubSubCategoryName(filterCategoryData[0]?.sN);

  }, [category, window.location.pathname]);

  
  const renderTileSlected = (singleData, index) => {
    console.log('single Data',singleData);
    const tileData = {
      name: singleData.subCatName,
      description: singleData.description,
      instruction: singleData.instruction,
      benifits: singleData.benifits,
      concequences: singleData.concequences,
      ...singleData,
    };
    return (
      <DetailComponent
        renderKey={`${singleData.subCatName}_${index}`}
        img={image1}
        tileData={tileData}
      />
    );
  };

  const renderTile = (singleData, index) => {
    const tileData = {
      name: singleData.subCatName,
      description: singleData.description,
      asnVideoPath:singleData.asnVideoPath,

      ...singleData,
    };
    return (
      <MemberComponentHorizontal
      setsubSubSelectedCategoryData={setsubSubSelectedCategoryData}
        renderKey={`${singleData.subCatName}_${index}`}
        img={image1}
        tileData={tileData}
        // link={`/${category}/${subCategoryName}/${subSubCategoryName}/${singleData.sN}`}
      />
    );
  };

  return (
    <Section id="navigation">
      <PageTitle title={`${subSubCategoryData.subCatName}`} />
      <Title>{`${subSubCategoryData.subCatName}`}</Title>
      <MainContainer>
      <ContainerSide>{subSubSelectedCategoryData?.map(renderTileSlected)}</ContainerSide>
      <Container>{subSubCategoryData?.subSubCat?.map(renderTile)}</Container>
    </MainContainer>
    <Wave/>

    </Section>
  );
};

export default SubSubCategory;
