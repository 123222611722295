import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation, Autoplay, EffectCards } from "swiper";

import image001 from "../../Icons/Avtar/yoga1.png";
import image002 from "../../Icons/Avtar/yoga2.png";
import image003 from "../../Icons/Avtar/yoga3.png";
import image004 from "../../Icons/Avtar/yoga4.png";
import image005 from "../../Icons/Avtar/yoga5.png";
import imageS002 from "../../Icons/Avtar/YogaS2.png";
import imageS001 from "../../Icons/Avtar/YogaS1.png";
import imageS003 from "../../Icons/Avtar/YogaS3.png";
import imageS004 from "../../Icons/Avtar/YogaS4.png";
import imageH001 from "../../Icons/Avtar/YogaH1.png";
import imageH002 from "../../Icons/Avtar/YogaH2.png";
import imageH003 from "../../Icons/Avtar/YogaH3.png";
import imageH004 from "../../Icons/Avtar/YogaH4.png";
import imageS005 from "../../Icons/Avtar/YogaS5.png";

import Arrow from "../../assets/Arrow.svg";

const Container = styled.div`
  width: 25vw;
  height: 70vh;

  @media (max-width: 70em) {
    height: 60vh;
  }
  @media (max-width: 64em) {
    height: 50vh;
    width: 30vh;
  }
  @media (max-width: 48em) {
    height: 50vh;
    width: 40vh;
  }
  @media (max-width: 30em) {
    height: 45vh;
    width: 40vh;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    background-image: linear-gradient(   ${(props) => props.theme.yellowShade},${(props) => props.theme.lightBlueShade});
    border-radius: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .swiper-button-next {
    color: ${(props) => props.theme.TextColorBlack};
    right: 0;
    width: 4rem;
    top: 45%;

    background-image: url(${Arrow});
    background-position: center;
    background-size: cover;

    &:after {
      display: none;
    }
    @media (max-width: 64em) {
      width: 3rem;
    }
    @media (max-width: 30em) {
      width: 2rem;
    }
  }
  .swiper-button-prev {
    color: ${(props) => props.theme.TextColorBlack};
    left: 0;
    transform: rotate(180deg);
    width: 4rem;
    top: 45%;

    background-image: url(${Arrow});
    background-position: center;
    background-size: cover;

    &:after {
      display: none;
    }
    @media (max-width: 64em) {
      width: 3rem;
    }
    @media (max-width: 30em) {
      width: 2rem;
    }
  }
`;
const Carousel = () => {
  return (
    <Container>
      <Swiper
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination={{
          type: "fraction",
        }}
        scrollbar={{
          draggable: true,
        }}
        navigation={true}
        effect={"cards"}
        grabCursor={true}
        modules={[Navigation, Autoplay, EffectCards]}
        className="mySwiper"
      >
        
        <SwiperSlide>
          <img style={{ height: "100%" }} src={imageS001} alt="The Weirdos" />{" "}
        </SwiperSlide>
        <SwiperSlide>
          <img style={{ height: "100%" }} src={image001} alt="The Weirdos" />{" "}
        </SwiperSlide>
        <SwiperSlide>
          <img style={{ height: "100%" }} src={imageH002} alt="The Weirdos" />{" "}
        </SwiperSlide>
        <SwiperSlide>
          <img style={{ height: "100%" }} src={image002} alt="The Weirdos" />{" "}
        </SwiperSlide>
        <SwiperSlide>
          <img style={{ height: "100%" }} src={imageS002} alt="The Weirdos" />{" "}
        </SwiperSlide>
        <SwiperSlide>
          <img style={{ height: "100%" }} src={imageH003} alt="The Weirdos" />{" "}
        </SwiperSlide>
        <SwiperSlide>
          <img style={{ height: "100%" }} src={imageH001} alt="The Weirdos" />{" "}
        </SwiperSlide>
        <SwiperSlide>
          <img style={{ height: "100%" }} src={imageS003} alt="The Weirdos" />{" "}
        </SwiperSlide>
        <SwiperSlide>
          <img style={{ height: "100%" }} src={image003} alt="The Weirdos" />{" "}
        </SwiperSlide>
        <SwiperSlide>
          <img style={{ height: "100%" }} src={imageH004} alt="The Weirdos" />{" "}
        </SwiperSlide>
        <SwiperSlide>
          <img style={{ height: "100%" }} src={imageS004} alt="The Weirdos" />{" "}
        </SwiperSlide>
        <SwiperSlide>
          <img style={{ height: "100%" }} src={image004} alt="The Weirdos" />{" "}
        </SwiperSlide>
        <SwiperSlide>
          <img style={{ height: "100%" }} src={image005} alt="The Weirdos" />{" "}
        </SwiperSlide>
        <SwiperSlide>
          <img style={{ height: "100%" }} src={imageS005} alt="The Weirdos" />{" "}
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};

export default Carousel;
