import React from "react";
import styled, { keyframes } from "styled-components";
import need from "../../../apiData/need.json";
import ButtonOrange from "../../common/ButtonOrange";
import { useNavigate } from "react-router-dom";

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding-bottom: 60px;
`;

const Row = styled.div`
width: 100%;
margin: 2rem;
display: flex;
justify-content: center;
align-items: center; 
flex-wrap: wrap;

/box-sizing: content-box;
  @media (max-width: 64em) {
    flex-direction: row;
    width: 100%;
  }
@media (max-width: 48em) {
  flex-direction: row;
  width: 100%;
  }
@media (max-width: 30em) {
  flex-direction: column;
  width: 100%;
  }
`;
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxl};
  text-transform: uppercase;
  color: ${(props) => props.theme.TextColorBlack};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5rem auto;
  text-align: center;
  width: fit-content;
  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontlg};
    padding-top: 15px;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
    padding-top: 13px;
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
    padding-top: 10px;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin:0% 2% 7% 2%;
  width: 20%;
  padding: 16px;
  background-image: linear-gradient( ${(props) => props.theme.yellowShade},  ${(props) => props.theme.lightBlueShade});
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.29);
  @media (max-width: 64em) {
    margin:0% 2% 13% 2%;
    width: 45%;
  }
  @media (max-width: 48em) {
  margin:0% 5% 15% 5%;
  width: 40%;

  }
  @media (max-width: 30em) {
  width: 80%;
  margin:0% 2% 20% 2%;
  }
`;


const Photo = styled.img`
    // margin-top: 21px;
    // margin-left: 24px;
    width: 60px;
    height: 60px;
    // align-item:center;
    // justify-content:center;
`;
const Testimonial = styled.div`
  font-size: ${(props) => props.theme.fontmd};
  padding-top: 10px;
  padding-bottom: 40px;
  line-height: 1.5;
  overflow-wrap: break-word;
  text-align: center;
  color: ${(props) => props.theme.buttonColor};
  cursor: pointer;
  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontmd};
    text-align: center;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontsm};
    text-align: justify;
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontxs};
    text-align: justify;
  }
`;

const Name = styled.p`
  color: ${(props) => props.theme.TextColorBlack};
  font-size: 14px;
  margin-bottom: 0px;
  text-align: center;
  font-weight: bold;
  `;
const ImageContainer = styled.div`
  // position: absolute;
  // margin-top: -266px;
  margin-top: -70px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 118px;
  width: 118px;
  border-radius: 50%;
  border: 6px solid ${(props) => props.theme.yellowShade}};
  background-color: white;

`
const Button = styled.button`
  display: inline-block;
  background-color: ${(props) => props.theme.buttonColor};
  color: ${(props) => props.theme.body};
  outline: none;
  // margin-bottom: 35%;
  font-size: ${(props) => props.theme.fontsm};
  padding: 0.9rem 2.3rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  &:hover {
    transform: scale(0.9);
  }
  &::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    border: 2px solid ${(props) => props.theme.body};
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: all 0.2s ease;
  }
  &:hover::after {
    transform: translate(-50%, -50%) scale(1);
    padding: 0.3rem;
  }
`;

const handleAnimation = () => {
  debugger;
};

const NftItem = (value, index) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/contactUs");
  };
  return (
    <Container key={`${index}`}>
      <ImageContainer>
        <Photo src={value.image} alt={value?.name} />
      </ImageContainer>
      <Name>{value?.name}</Name>
      <Testimonial>{value?.comments}</Testimonial>
      {/* <Button onClick={() => handleClick()}> Book Online Course</Button> */}
    </Container>
  );
};

const Need = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/contactUs");
  };
  return (
    <Section id="testimonial">
      <Title>The YogaShastra Difference</Title>
      <Row id="rowData" direction="alternate-reverse">
        {need?.map(NftItem)}
      </Row>
      {/* <ButtonOrange text="Request A Free Consultation" action={() => handleClick()} /> */}
    </Section>
  );
};

export default Need;
