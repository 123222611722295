import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import TypeWriterText from "../../components/common/TypeWriterText";
// import CoverVideo from "../common/CoverVideo";
import RoundTextBlack from "../../assets/YogRoundBlack.png";
import HomeImage from "../../assets/Group 109.png";
import Popup from "./FreeTrial/Popup"; // Adjust the import path accordingly
import { useNavigate } from "react-router-dom";

const Section = styled.section`
  position: relative;
  background-image: linear-gradient(
    ${(props) => props.theme.lightBlueShade},
    ${(props) => props.theme.yellowShade}
  );
  margin-top: 21px;
`;

const Container = styled.div`
  width: 85%;
  min-height: 80vh;
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  margin-top: -21px;
  @media (max-width: 64em) {
    width: 85%;
    min-height: 70vh;
  }
  @media (max-width: 48em) {
    flex-direction: column-reverse;
    min-height: 60vh;
    width: 100%;
    & > *:first-child {
      width: 100%;
      margin-top: 2rem;
    }
  }
  @media (max-width: 30em) {
    min-height: 50vh;
  }
`;

const Box = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  postion: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const rotate = keyframes`
100%{
    transform: rotate(1turn);
}`;

const Round = styled.div`
  position: absolute;
  bottom: 4rem;
  right: 90%;
  width: 6rem;
  height: 6rem;
  border: 1px solid ${(props) => props.theme.buttonColor};
  border-radius: 50%;
  img {
    width: 100%;
    height: auto;
    animation: ${rotate} 6s linear infinite reverse;
  }
  @media (max-width: 64em) {
    width: 4rem;
    height: 4rem;
    left: none;
    right: 2rem;
    bottom: 100%;
    display: none;
  }
  @media (max-width: 48em) {
    display: none;
  }
`;

const Circle = styled.span`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: ${(props) => props.theme.buttonColor};
  color: ${(props) => props.theme.buttoncolorO};
  font-size: ${(props) => props.theme.fontlg};

  @media (max-width: 64em) {
    width: 1.5rem;
    height: 1.5rem;
    font-size: ${(props) => props.theme.fontmd};
  }
`;
const HomeImages = styled.img`
  width: 75%;
  height: auto;
  object-fit: cover;
  margin-top: 4%;
`;
const backDivImagess = styled.div`
  // position: absolute;
  width: 220px;
  height: 300px;
  background-color: ${(props) => props.theme.buttonColor};
`;

const Wave = styled.div`
  display: block;
  position: relative;
  height: 40px;
  width: 100%;
  background-color: ${(props) => props.theme.yellowShade};
  transform: scale(1, 1.5);

  &::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    width: 100%;
    height: 300px;
    background-color: white;
    right: -25%;
    top: 20px;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    width: 100%;
    height: 300px;
    background-color: ${(props) => props.theme.yellowShade};
    left: -25%;
    top: -240px;
    clip-path: ellipse(100% 15% at -15% 100%);
  }
`;

const Home = () => {
  const [showPopup, setShowPopup] = useState(true);
  const navigate = useNavigate();

  const handlePopupOpen = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    console.log("handlePopupClose clicked");
    setShowPopup(false);
  };

  return (
    <>
      <Section id="home">
        <Container>
          <Box>
            <TypeWriterText />
          </Box>
          <Box>
            {/* <div style={{
          position: "absolute",
          borderTop: "858px solid #D4EAD0",
          borderRight: "364px solid #D4EAD0",
          borderLeft: "906px solid transparent",
          opacity: 1,
          zIndex: -30,
          overflow: "hidden",
        }}></div> */}

            <HomeImages src={HomeImage} alt=""></HomeImages>
          </Box>

          <Round>
            <Circle>&#x2193;</Circle>
            <img src={RoundTextBlack} alt="NFT" />
          </Round>
        </Container>
        <Wave />
      </Section>
      {showPopup && <Popup onClose={handlePopupClose} />}
    </>
  );
};

export default Home;
