import React from "react";
import styled, { keyframes } from "styled-components";
import utils from "../../common/utils";

import testimonials from "../../../apiData/testimonials.json";

const Section = styled.section`
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding-bottom: 60px;
  & > *:first-child {
    animation-duration: 40s;
    @media (max-width: 30em) {
      animation-duration: 10s;
    }
  }
  & > *:last-child {
    animation-duration: 40s;
    @media (max-width: 30em) {
      animation-duration: 10s;
    }
  }
`;
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.buttonColor};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  width: fit-content;
  @media (max-width: 50em) {
    font-size: ${(props) => props.theme.fontlg};
    width: 100%

  }
`;
const move = keyframes`
0%{transform: translateX(30%)};
30%{transform: translateX(-30%)}
@media (max-width: 30em) {

  0%{transform: translateX(100%)};
  100%{transform: translateX(-100%)} 
}
`;

const Row = styled.div`
  // white-space: nowrap;
  box-sizing: content-box;
  margin: 2rem;
  display: flex;
  // position: relative;
  animation: ${move} 
  infinite ${(props) => props.direction};

  @media (max-width: 30em) {
    flex-direction: column;
    animation-play-state: paused;
  }
  &:hover {
    animation-play-state: paused;
  }
`;

const Container = styled.div`
  position: relative;
  margin: 0px 10px;
  width: 340px;
  padding: 16px;
  // background: ${(props) => props.theme.lightGreen};
  background-image: linear-gradient(   ${(props) => props.theme.lightBlueShade},${(props) => props.theme.yellowShade});
  
  display: block;
  border-radius: 10px;

  ::after {
    content: "‘‘";
    position: absolute;
    top: 20px;
    left: 48%;
    font-weight: bold;
    font-size: 40px;
    color: ${(props) => props.theme.buttonColor};
  }

  @media (max-width: 30em) {
    margin: 0px 0px 35px;
    margin-bottom: 60px;
  }
`;

const Photo = styled.img`
  position: absolute;
  bottom: -45px;
  height: 90px;
  width: 90px;
  left: 125px;
  border-radius: 50%;
  border: 2px solid ${(props) => `rgba(${props.theme.textRgba},0.9)`};
  background-color: ${(props) => props.theme.yellowShade};
`;

const Testimonial = styled.div`
  font-size: 0.9rem;
  padding-top: 50px;
  padding-bottom: 75px;
  line-height: 1.5;
  overflow-wrap: break-word;
  text-align: center;
  color: ${(props) => props.theme.buttonColor};

  position: relative;
  cursor: pointer;

  .tooltip {
    /* hide and position tooltip */
    top: 10px;
    background-color: #000000ba;
    color: white;
    border-radius: 5px;
    opacity: 0;
    position: absolute;
    -webkit-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -ms-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
    transition: opacity 0.5s;
  }

  &:hover .tooltip {
    /* display tooltip on hover */
    opacity: 1;
  }
`;

const Name = styled.p`
  display: block;
  color: ${(props) => props.theme.buttonColor};
  font-size: 14px;
  margin-bottom: 0px;
  text-align: center;
  font-weight: bold;
  // ::before {
  //   content: '';
  //   width: 100%;
  //   height: 1px;
  //   background: #3C362A;
  //   display: block;
  //   margin: 5px auto 10px
  // }
`;

const Profession = styled.p`
  display: block;
  color: ${(props) => props.theme.buttonColor};
  font-size: 11px;
  margin-bottom: 25px;
  text-align: center;
  margin-top: 0px;

  // ::before {
  //   content: '';
  //   width: 100%;
  //   height: 1px;
  //   background: #3C362A;
  //   display: block;
  //   margin: 5px auto 10px
  // }
`;

const handleAnimation = () => {
  debugger;
};

const NftItem = (value, index) => {
  return (
    <Container key={`${index}`}>
      <Photo src={value.image} alt={value?.name} />
      <Testimonial onFocus={handleAnimation} className="text">
        {utils.getShortDesc(value?.comments)}
        <div className="tooltip">{value?.comments}</div>
      </Testimonial>
      <div
        style={{
          position: "absolute",
          bottom: "20px",
          width: "calc( 100% - 32px)",
        }}
      >
        <Name>{value?.name}</Name>
        <Profession>{value?.profession}</Profession>
      </div>
    </Container>
  );
};

const Testimonials = () => {
  return (
    <Section id="testimonial">
      <Title>What Clients says</Title>
      <Row id="rowData" direction="alternate-reverse">
        {testimonials?.map(NftItem)}
      </Row>
    </Section>
  );
};

export default Testimonials;
