import { createGlobalStyle } from "styled-components";
import "@fontsource/akaya-telivigala"
import "@fontsource/sora"



const GlobalStyles = createGlobalStyle`


*,*::before,*::after{
    margin: 0;
    padding: 0;
}

#root{
  overflow-x: hidden;
}

  body{
    font-family: 'Sora',sans-serif;
    overflow-x: hidden;
    
  }
  h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
  }
  a{
    color: inherit;
    text-decoration: none;
  }

  body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

body::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #00401dba;
}
`
export default GlobalStyles;




